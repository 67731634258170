import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) + de 15",
    correct: false,
  },
  {
    option: "b) + de 20",
    correct: false,
  },
  {
    option: "c) + de 30",
    correct: true,
  },
  {
    option: "d) + de 40",
    correct: false,
  },
  {
    option: "e) + de 50",
    correct: false,
  },
];

export default function Question1(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question1: {
          title: "Quantas empresas fazem parte do Grupo Stefanini ?",
          option: option.option,
          correct: option.correct,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t("Quantas empresas fazem parte do Grupo Stefanini ?")}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
