import React, { useState, useEffect } from "react";

export default function OptionQuestion(props) {
  const { e, answered, setAnswered, code, showCorrects } = props;
  const [selected, setSelected] = useState(null);
  const [bgColor, setBgColor] = useState("whitesmoke");

  function selectingResponse(option) {
    setSelected(option);

    var allAnswerd = answered;

    var search = allAnswerd.find((res) => res.title === e.title);

    if (search) {
      allAnswerd = answered.filter((res) => res.title !== e.title);
    }

    allAnswerd.push({
      title: e.title,
      response: option.response,
      correct: option.correct,
      code,
    });

    setAnswered(allAnswerd);
  }

  function verifyBgColor() {
    console.log("ok 2");
    if (selected?.correct) {
      setBgColor("#78e378");
    } else setBgColor("#f04040");
  }

  useEffect(() => {
    if (showCorrects) {
      verifyBgColor();
    }
  }, [showCorrects]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: bgColor,
      }}
      className="card-question-kenvue"
    >
      <p>{e.type}</p>
      <h4>{e.title}</h4>
      {e.subtitle && <p>{e.subtitle}</p>}
      <p style={{ display: "flex", flexDirection: "column" }}>
        {e.options.map((option, i) => (
          <a
            id={option.response}
            className={`option-card-kenvue 
            ${
              selected?.response === option.response
                ?
              // option.correct ? 
              "option-card-kenvue-selected" : ""
            }
            `}
            onClick={() => {
              if (!showCorrects) {
                selectingResponse(option);
              }
            }}
            style={{
              border:
                showCorrects && option.correct ? "1px solid black" : "none",
                opacity: showCorrects && !option.correct ? 0.5 : 1
            }}
          >
            {option.response}
          </a>
        ))}
      </p>
    </div>
  );
}
