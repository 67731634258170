import axios from "axios";
// const urlBase = `${process.env.REACT_APP_URL_BASE}/api-admin-ranking`;
const urlBase = `https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-ranking`;

async function createEarnedPoints(dataRanking, event, login) {
  const data = JSON.stringify({ ...dataRanking });
  var config = {
    method: "post",
    url: `${urlBase}/events/${event}/logins/${login}/earnedpointcreate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
async function getTotalPoints(event, login) {
  const data = JSON.stringify({ event, login });
  var config = {
    method: "get",
    url: `${urlBase}/events/${event}/logins/${login}/totalpoints`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { createEarnedPoints, getTotalPoints };
