export const hotels = [
  {
    value: "Hospital das Clínicas (HC) e InCOR",
    label: "Hospital das Clínicas (HC) e InCOR",
  },
  { value: "Santa Casa de São Paulo", label: "Santa Casa de São Paulo" },
  {
    value: "Conjunto Hospitalar do Mandaqui",
    label: "Conjunto Hospitalar do Mandaqui",
  },
  {
    value: "Hospital Municipal do Tatuapé",
    label: "Hospital Municipal do Tatuapé",
  },
  {
    value: "Complexo Hospitalar Padre Bento",
    label: "Complexo Hospitalar Padre Bento",
  },
  {
    value: "Escola Paulista de Medicina (EPM/UNIFESP)",
    label: "Escola Paulista de Medicina (EPM/UNIFESP)",
  },
  { value: "Hospital Santa Marcelina", label: "Hospital Santa Marcelina" },
];
