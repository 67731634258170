import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// img
import Leite from "../../assets/images/neogen/leite.png";
import LeitePo from "../../assets/images/neogen/leitePo.png";
import Iorgut from "../../assets/images/neogen/iorgut.png";
import Queijo from "../../assets/images/neogen/queijo.png";

const data = [
  {
    img: LeitePo,
    title: "Leite em pó",
  },
  {
    img: Leite,
    title: "Leite UHT",
  },
  {
    img: Iorgut,
    title: "Iogurte sem lactose",
  },
  {
    img: Queijo,
    title: "Queijo",
  },
];

export default function ChooseProduct(props) {
  const { setStep, productSelected, setProductSelected, cancelGame } = props;
  const [warningAlert, setWarningAlert] = useState("");

  function nextStep() {
    if (!productSelected) {
      setWarningAlert("Selecione um produto");
      return;
    }

    setStep(3);
  }

  return (
    <>
      {warningAlert && (
        <SweetAlert
          warning
          title="Atenção"
          onConfirm={() => {
            setWarningAlert("");
          }}
        >
          {warningAlert}
        </SweetAlert>
      )}
      <p className="p-about-neogen">
        <a>Selecione o produto que você prefere que seja produzido:</a>
      </p>

      <div className="div-all-option-product">
        {data.map((e, i) => (
          <div
            key={i}
            className={`div-option-product ${
              productSelected === e.title && "div-option-product-selected"
            }`}
            onClick={() => setProductSelected(e.title)}
          >
            <img src={e.img} className="img-option-product" />
            <a
              className={`title-option-product ${
                productSelected === e.title && "title-option-product-selected"
              }`}
            >
              {e.title}
            </a>
          </div>
        ))}
      </div>

      <Row>
        <Col md={12}>
          <div className="div-btn-submit">
            {cancelGame && (
              <Button
                className="btn-danger"
                style={{ marginRight: 20 }}
                onClick={() => window.location.reload()}
              >
                Voltar ao início
              </Button>
            )}
            <Button className="btn-success" onClick={() => nextStep()}>
              Próximo
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
