export const leitePoMaterias = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "MATERIASPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "MATERIASPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "MATERIASLEITORADEPLACAS",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "MATERIASCOLETAAMOSTRAS20",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "MATERIASMEIOSCULTURA",
  },
  {
    title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    code: "MATERIASMONITORAMENTOHIGIENE20",
  },
];

export const leiteUhtMaterias = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "MATERIASPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "MATERIASPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "MATERIASLEITORADEPLACAS",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "MATERIASCOLETAAMOSTRAS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "MATERIASMEIOSCULTURA",
  },
  {
    title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    code: "MATERIASMONITORAMENTOHIGIENE",
  },
];

export const iogurteMaterias = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "MATERIASPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "MATERIASPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "MATERIASLEITORADEPLACAS",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "MATERIASCOLETAAMOSTRAS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "MATERIASMEIOSCULTURA",
  },
  {
    title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    code: "MATERIASMONITORAMENTOHIGIENE",
  },
];

export const queijoMaterias = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "MATERIASPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "MATERIASPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "MATERIASLEITORADEPLACAS",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "MATERIASCOLETAAMOSTRAS20",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "MATERIASMEIOSCULTURA",
  },
  {
    title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    code: "MATERIASMONITORAMENTOHIGIENE20",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "MATERIASREVEAL",
  },
];

export const leitePoMonitoramento = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "MONITORAMENTOPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "MONITORAMENTOPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "MONITORAMENTOLEITORAPLACAS",
  },
  {
    title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    code: "MONITORAMENTOMDS2",
  },
  {
    title: "Testes automatizados para indicadores – Soleris",
    code: "MONITORAMENTOSOLERIS2",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "MONITORAMENTOCOLETAAMOSTRAS",
  },
  {
    title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    code: "MONITORAMENTOTESTESALERGENICOS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "MONITORAMENTOMEIOSCULTURA",
  },
  {
    title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    code: "MONITORAMENTOMONITORAMENTOHIGIENE",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "MONITORAMENTOREVEAL20",
  },
];

export const leiteUhtMonitoramento = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "MONITORAMENTOPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "MONITORAMENTOPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "MONITORAMENTOLEITORAPLACAS",
  },
  {
    title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    code: "MONITORAMENTOMDS2",
  },
  {
    title: "Testes automatizados para indicadores – Soleris",
    code: "MONITORAMENTOSOLERIS2",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "MONITORAMENTOCOLETAAMOSTRAS",
  },
  {
    title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    code: "MONITORAMENTOTESTESALERGENICOS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "MONITORAMENTOMEIOSCULTURA",
  },
  {
    title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    code: "MONITORAMENTOMONITORAMENTOHIGIENE",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "MONITORAMENTOREVEAL20",
  },
];

export const iogurteMonitoramento = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "MONITORAMENTOPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "MONITORAMENTOPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "MONITORAMENTOLEITORAPLACAS",
  },
  {
    title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    code: "MONITORAMENTOMDS2",
  },
  {
    title: "Testes automatizados para indicadores – Soleris",
    code: "MONITORAMENTOSOLERIS2",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "MONITORAMENTOCOLETAAMOSTRAS",
  },
  {
    title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    code: "MONITORAMENTOTESTESALERGENICOS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "MONITORAMENTOMEIOSCULTURA",
  },
  {
    title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    code: "MONITORAMENTOMONITORAMENTOHIGIENE",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "MONITORAMENTOREVEAL20",
  },
];

export const queijoMonitoramento = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "MONITORAMENTOPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "MONITORAMENTOPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "MONITORAMENTOLEITORAPLACAS",
  },
  {
    title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    code: "MONITORAMENTOMDS2",
  },
  {
    title: "Testes automatizados para indicadores – Soleris",
    code: "MONITORAMENTOSOLERIS2",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "MONITORAMENTOCOLETAAMOSTRAS",
  },
  {
    title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    code: "MONITORAMENTOTESTESALERGENICOS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "MONITORAMENTOMEIOSCULTURA",
  },
  {
    title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    code: "MONITORAMENTOMONITORAMENTOHIGIENE",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "MONITORAMENTOREVEAL20",
  },
];

export const leitePoControle = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "QUALIDADEPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "QUALIDADEPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "QUALIDADELEITORADEPLACAS",
  },
  {
    title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    code: "QUALIDADEMDS",
  },
  {
    title: "Testes automatizados para indicadores – Soleris",
    code: "QUALIDADESOLERIS",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "QUALIDADECOLETAAMOSTRAS20",
  },
  {
    title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    code: "QUALIDADETESTESALERGENICOS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "QUALIDADEMEIOSCULTURA",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "QUALIDADEREVEAL20",
  },
];

export const leiteUhtControle = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "QUALIDADEPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "QUALIDADEPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "QUALIDADELEITORADEPLACAS",
  },
  {
    title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    code: "QUALIDADEMDS",
  },
  {
    title: "Testes automatizados para indicadores – Soleris",
    code: "QUALIDADESOLERIS",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "QUALIDADECOLETAAMOSTRAS",
  },
  {
    title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    code: "QUALIDADETESTESALERGENICOS",
  },
  {
    title: "Análise rápida de produtos UHT – MLS",
    code: "QUALIDADEMLS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "QUALIDADEMEIOSCULTURA",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "QUALIDADEREVEAL20",
  },
];

export const iogurteControle = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "QUALIDADEPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "QUALIDADEPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "QUALIDADELEITORADEPLACAS",
  },
  {
    title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    code: "QUALIDADEMDS",
  },
  {
    title: "Testes automatizados para indicadores – Soleris",
    code: "QUALIDADESOLERIS",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "QUALIDADECOLETAAMOSTRAS",
  },
  {
    title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    code: "QUALIDADETESTESALERGENICOS",
  },
  {
    title: "Análises Nutricionais - Megazyme",
    code: "QUALIDADEANALISESNUTRICIONAIS",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "QUALIDADEMEIOSCULTURA",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "QUALIDADEREVEAL20",
  },
];

export const queijoControle = [
  {
    title: "Análise de Indicadores – Placas Petrifilm Padrão",
    code: "QUALIDADEPLACASPETRIFILM",
  },
  {
    title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    code: "QUALIDADEPLACASRAPIDAS",
  },
  {
    title:
      "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    code: "QUALIDADELEITORADEPLACAS",
  },
  {
    title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    code: "QUALIDADEMDS",
  },
  {
    title: "Testes automatizados para indicadores – Soleris",
    code: "QUALIDADESOLERIS",
  },
  {
    title: "Coleta de amostras (sacos, esponjas, swabs)",
    code: "QUALIDADECOLETAAMOSTRAS20",
  },
  {
    title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    code: "QUALIDADETESTESALERGENICOS10",
  },
  {
    title: "Análise de Indicadores e patógenos – Meios de cultura",
    code: "QUALIDADEMEIOSCULTURA",
  },
  {
    title: "Detecção de Patógenos – Reveal 2.0",
    code: "QUALIDADEREVEAL20",
  },
];
