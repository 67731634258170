import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ptBrTranslate from './ptbr.json';
import enTranslate from './en.json';
import esTranslate from './es.json';

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: enTranslate,
    es: esTranslate,
    ptBr: ptBrTranslate,
  }
})