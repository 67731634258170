import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalBody } from "reactstrap";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

export default function FinalModal(props) {
  const { t } = useContext(QuizContext);
  const { isOpen, toggle, questions } = props;
  const [corrects, setCorrects] = useState(0);

  useEffect(() => {
    var totalCorrect = 0;

    if (questions.question8.correct) totalCorrect = totalCorrect + 1;
    if (questions.question9.correct) totalCorrect = totalCorrect + 1;

    setCorrects(totalCorrect);
  }, [questions]);
  return (
    <Modal isOpen={isOpen} centered autoFocus>
      {/* <ModalHeader toggle={toggle} /> */}
      <ModalBody>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h4 style={{ textAlign: "center" }}>
            {t("Formulário enviado com sucesso!")}
          </h4>

          <a style={{ textAlign: "center", fontSize: 15 }}>
            {t("Você acertou")}: {corrects}/2
          </a>

          <a className="btn-close-modal" onClick={toggle}>
            {t("Fechar")}
          </a>
        </div>
      </ModalBody>
    </Modal>
  );
}
