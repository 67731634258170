import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../../avipam/styled.css";

//component
import BodyCard from "../../../components/bodyCard";

const alternatives = [
  { name: "ELOPAR", isCorrect: false },
  { name: "RIACHUELO", isCorrect: false },
  { name: "FIEP", isCorrect: true },
  { name: "BRADESCO", isCorrect: false },
]

export default function AvipamEnquete5() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);

  return (
    <div className="section-files">
      <div className="div-files">
        <BodyCard
          user={user}
          type={"Enquete 5"}
          fileName="Enquete5"
          title={"Qual cliente que temos posto em Curitiba?"}
          alternatives={alternatives}
          description={[{ data: "" }]}
          id={"1b386d61-86bb-45a1-a488-cefdf16974ae"}
          button={"Enviar"}
          codeRanking="ENQUETE5-EXTERNO"
        />
      </div>
    </div>
  );
}
