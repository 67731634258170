import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./style.css";

const questions = [
  {
    option: "a) Apenas 1 e 2 são verdadeiras",
    correct: false,
  },
  {
    option: "b) Apenas 3 e 4 são verdadeiras",
    correct: false,
  },
  {
    option: "c) Apenas 1, 2, 3 e 5 são verdadeiras",
    correct: true,
  },
  {
    option: "d) Todas são verdadeiras",
    correct: false,
  },
];

export default function Question2(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question2: {
          title:
            "Sobre a segmentação de contas [médicos e instituições], quais são as afirmativas verdadeiras:",
          option: option.option,
          correct: option.correct,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t(
          "Sobre a segmentação de contas [médicos e instituições], quais são as afirmativas verdadeiras:"
        )}
      </h5>
      <div style={{ textAlign: "center" }}>
        <p style={{ marginTop: "20px" }}>
          1 - A segmentação é necessária para identificar as contas de maior
          potencial para a Boehringer e para cada território.
        </p>
        <p>
          2 - Através da segmentação, é possível personalizar as interações com
          os clientes.
        </p>
        <p>
          3 - Além do potencial, a segmentação também serve para identificar e
          agrupar clientes com as mesmas características.
        </p>
        <p>
          4 - A segmentação de contas é definida com base somente na opinião da
          força de vendas.
        </p>
        <p>
          5 - A segmentação de contas é realizada considerando dados
          quantitativos e/ou qualitativos conforme as características do
          mercado/produto.
        </p>
      </div>

      <div className="div-options-question-boe">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-boe ${
              optionSelected === e.option && "selected-option-question-boe"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
