import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) 3",
    correct: false,
  },
  {
    option: "b) 2",
    correct: false,
  },
  {
    option: "c) 2 e 3",
    correct: false,
  },
  {
    option: "d) Todas são características da Alice",
    correct: true,
  },
  {
    option: "e) 1",
    correct: false,
  },
];

export default function Question27(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question27: {
          title:
            "Qual das alternativas a seguir não é uma característica da Alice?",
          option: option.option,
          correct: option.correct,
          order: 27,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30, textAlign: "center" }}>
      <h5 style={{ textAlign: "center" }}>
        {t("Qual das alternativas a seguir não é uma característica da Alice?")}
      </h5>

      <p>
        {t(
          "1. Alinhamento dos resultados do modelo com as estratégias de negócio"
        )}
      </p>
      <p>
        {t(
          "2. Resultados prescritivos focados na identificação de ações de negócios"
        )}
      </p>
      <p>{t("3. Identificar o DNA dos pontos problemáticos")}</p>
      <p>
        {t(
          `4. Propor ações de acordo com a intensidade da dor (isso resolve uma questão fundamental para as empresas quando investir em um cliente para atingir um propósito comercial ou não investir)`
        )}
      </p>
      <p>
        {t("5. Acompanhamento individual do desempenho das ações empresariais")}
      </p>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
