import React, { useState, useEffect } from "react";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// router dom
import { useLocation, useNavigate } from "react-router-dom";
// componentes
import Question from "../question";
// service
import { postSurvey } from "../../../services/survey";

export default function PDV3() {
  const { user } = useLocation().state;
  const history = useNavigate();

  const [assortment, setAssortment] = useState(0);
  const [price, setPrice] = useState(0);
  const [merchandising, setMerchandising] = useState(0);
  const [iceCream, setIceCream] = useState(0);
  const [topping, setTopping] = useState(0);
  const [total, setTotal] = useState(0);
  // alertas
  const [successAlert, setSuccessAlert] = useState(false);
  const [erroAlert, setErroAlert] = useState(false);
  // btn
  const [loading, setLoading] = useState(false);

  function submitForm() {
    if (!assortment || !price || !merchandising || !iceCream || !topping) {
      setErroAlert(true);
      setLoading(false);
    } else {
      sendToApi(
        "Quali Rota(Dia 1 - Bar)",
        (assortment * (25 / 100)).toFixed(2)
      ).then(() =>
        sendToApi("Preço(Dia 1 - Bar)", (price * (20 / 100)).toFixed(2)).then(
          () =>
            sendToApi(
              "Merchandising(Dia 1 - Bar)",
              (merchandising * (20 / 100)).toFixed(2)
            ).then(() =>
              sendToApi(
                "Produto gelado(Dia 1 - Bar)",
                (iceCream * (15 / 100)).toFixed(2)
              ).then(() =>
                sendToApi(
                  "Cobertura Bom Parceiro(Dia 1 - Bar)",
                  (topping * (20 / 100)).toFixed(2)
                ).then(() =>
                  sendToApi("Total (Dia 1 - Bar)", total).then(() =>
                    setSuccessAlert(true)
                  )
                )
              )
            )
        )
      );
    }
  }

  async function sendToApi(title, response) {
    const data = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: title,
      answer: response,
      hit: true,
    };

    await postSurvey(data);
  }

  useEffect(() => {
    var countTotal =
      assortment * (25 / 100) +
      price * (20 / 100) +
      merchandising * (20 / 100) +
      iceCream * (15 / 100) +
      topping * (20 / 100);

    setTotal(countTotal.toFixed(2));
  }, [assortment, price, merchandising, iceCream, topping]);

  return (
    <>
      {successAlert && (
        <SweetAlert
          success
          title="Enviado!"
          onConfirm={() => {
            history(-1);
          }}
        >
          Pesquisa respondida com sucesso.
        </SweetAlert>
      )}

      {erroAlert && (
        <SweetAlert
          warning
          title="Responda todas as perguntas!"
          onConfirm={() => {
            setErroAlert(false);
          }}
        />
      )}

      <section
        className="section-card-petropolis"
        style={{ justifyContent: "flex-start" }}
      >
        <h3 style={{ textAlign: "center", color: "white" }}>
          Avalie como está a execução deste PDV de 1 a 10:
        </h3>

        <Question
          title="Quali Rota"
          optionSelected={assortment}
          setOptionSelected={setAssortment}
        />

        <Question
          title="Preço"
          optionSelected={price}
          setOptionSelected={setPrice}
        />

        <Question
          title="Merchandising"
          optionSelected={merchandising}
          setOptionSelected={setMerchandising}
        />

        <Question
          title="Produto gelado"
          optionSelected={iceCream}
          setOptionSelected={setIceCream}
        />

        <Question
          title="Cobertura Bom Parceiro"
          optionSelected={topping}
          setOptionSelected={setTopping}
        />

        <a style={{ color: "white", fontSize: 17 }}>Total: {total}</a>

        <a
          className="btn-submit"
          onClick={() => {
            if (!loading) {
              setLoading(true);
              submitForm();
            }
          }}
          style={{ marginTop: 10 }}
        >
          Enviar
        </a>
      </section>
    </>
  );
}
