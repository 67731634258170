import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option:
      "a) Identificando casos onde os dados históricos possam se ressignificados",
    correct: false,
  },
  {
    option:
      "b) Identificando volumes e custos de armazenamento de dados históricos",
    correct: false,
  },
  {
    option:
      "c) Identificando volumes periódicos (mensais e anuais) de dados que devem ser armazenados por longos períodos de tempo",
    correct: false,
  },
  {
    option:
      "d) Avaliando o valor dos dados em função da sensibilidade e valor dos mesmos",
    correct: false,
  },
  {
    option: "e) Todas acima",
    correct: true,
  },
];

export default function Question30(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question30: {
          title: "Como qualificar uma oportunidade do DORA?",
          option: option.option,
          correct: option.correct,
          order: 30
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t("Como qualificar uma oportunidade do DORA?")}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
