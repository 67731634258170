import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) Somente cloud",
    correct: false,
  },
  {
    option: "b) Somente OnPremisse",
    correct: false,
  },
  {
    option:
      "c) O SAI ES atua penas na camada de Event Management and Triage (NOC). Nem em cloud nem em OnPremisse",
    correct: false,
  },
  {
    option:
      "d) Em ambas as plataformas. Um dos propósitos do SAI ES é a gestão de multiplataformas/solução híbrida evitando duplicidade de skills e gestão heterogenia do ambiente",
    correct: true,
  },
];

export default function Question36(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question36: {
          title:
            "O SAI ES é aplicável apenas a soluções cloud ou apenas a soluções OnPremisse?",
          option: option.option,
          correct: option.correct,
          order: 36
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t(
          "O SAI ES é aplicável apenas a soluções cloud ou apenas a soluções OnPremisse?"
        )}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
