import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) tecnologia, serviços",
    correct: false,
  },
  {
    option: "b) modelos pré-construídos",
    correct: false,
  },
  {
    option: "c) tecnologia, serviços, metodologia e modelos pré-construídos",
    correct: true,
  },
  {
    option: "d) tecnologia",
    correct: false,
  },
  {
    option: "e) serviços, modelos pré-construídos",
    correct: false,
  },
];

export default function Question26(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question26: {
          title:
            "Alice é uma solução que inclui tecnologia, serviços, metodologia e modelos pré-construídos?",
          option: option.option,
          correct: option.correct,
          order: 26
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t(
          "Alice é uma solução que inclui tecnologia, serviços, metodologia e modelos pré-construídos?"
        )}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
