import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import "./styled.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { postSurvey } from "../../../services/survey";
import Select from "react-select";
import { hotels } from "./hotels";
import { verifySurveyByUser } from "../../../services/survey";
import CardQuestionAnswered from "./cardQuestionAnswered";

export default function AcheTransporte() {
  const [user, setUser] = useState();
  const [hotel, setHotel] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [placa, setPlaca] = useState("");
  const [other, setOtherOption] = useState("");
  const [searchParams] = useSearchParams();
  const [optionTransport, setOptionTransport] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [step, setStep] = useState(null);

  const clearAll = () => {
    setHotel("");
    setMarca("");
    setModelo("");
    setPlaca("");
    setOtherOption("");
  };

  async function submitForm(e) {
    setBtnLoading(true);

    if (!optionTransport) {
      setErrorAlert('Seleciona uma opção para "Meio de transporte"');
      return;
    } else setErrorAlert("");

    if (optionTransport === "Carro Próprio") {
      if (marca === "" || modelo === "" || placa === "") {
        setErrorAlert(
          'Os campos Marca, Modelo e Placa do veículo são obrigatórios *"'
        );
        return;
      } else setErrorAlert("");
    }

    if (optionTransport === "Transfer") {
      if (hotel === "") {
        setErrorAlert("O campo Ponto de Saída é obrigatório *");
        return;
      } else setErrorAlert("");
    }

    if (optionTransport === "Outros") {
      if (other === "") {
        setErrorAlert(
          'O campo "Descreva o seu meio de transporte abaixo:" é obrigatório *'
        );
        return;
      } else setErrorAlert("");
    }

    const question1 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Qual meio de transporte irá utilizar?",
      answer: optionTransport,
      hit: true,
    };
    await postSurvey(question1)
      .then((res) => {
        // console.log("reposta 1", res);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );
    if (optionTransport === "Carro Próprio") {
      const question2 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: "Marca",
        answer: marca,
        hit: true,
      };
      await postSurvey(question2)
        .then((res) => {
          // console.log("reposta 2", res);
        })
        .catch(() =>
          setErrorAlert(
            "Erro ao enviar as resposta do formulário, tente novamente em instantes"
          )
        );

      const question3 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: "Modelo",
        answer: modelo,
        hit: true,
      };
      await postSurvey(question3)
        .then((res) => {
          // console.log("reposta 3", res);
        })
        .catch(() =>
          setErrorAlert(
            "Erro ao enviar as resposta do formulário, tente novamente em instantes"
          )
        );

      const question4 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: "Placa",
        answer: placa,
        hit: true,
      };
      await postSurvey(question4)
        .then((res) => {
          // console.log("reposta 4", res);
        })
        .catch(() =>
          setErrorAlert(
            "Erro ao enviar as resposta do formulário, tente novamente em instantes"
          )
        );
    }

    if (optionTransport === "Transfer") {
      const question5 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: "Ponto de Saída",
        answer: hotel,
        hit: true,
      };
      await postSurvey(question5)
        .then((res) => {
          // console.log("reposta 5", res);
        })
        .catch(() =>
          setErrorAlert(
            "Erro ao enviar as resposta do formulário, tente novamente em instantes"
          )
        );
    }

    if (optionTransport === "Outros") {
      const question6 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: "Descreva o seu meio de transporte abaixo",
        answer: other,
        hit: true,
      };
      await postSurvey(question6)
        .then((res) => {
          // console.log("reposta 5", res);
        })
        .catch(() =>
          setErrorAlert(
            "Erro ao enviar as resposta do formulário, tente novamente em instantes"
          )
        );
    }

    setSuccessAlert(true);
  }

  useEffect(() => {
    if (window.location.search !== "") {
      setUser({
        idEvent: searchParams.get("idEvent"),
        idPart: searchParams.get("idPart"),
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      verifySurveyByUser({
        eventId: user.idEvent,
        loginId: user.idPart,
        question: "Qual meio de transporte irá utilizar?",
      }).then((res) => {
        if (res.message === "QUESTION_ANSWERED") {
          setStep("b");
        } else if (res.message === "QUESTION_AVAILABLE") {
          setStep("a");
        } else return;
      });
    }
  }, [user]);

  return (
    <div style={{ backgroundColor: "#D9166E" }}>
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {errorAlert ? (
          <SweetAlert
            warning
            title={"Alerta!"}
            onConfirm={() => {
              setBtnLoading(false);
              setErrorAlert("");
            }}
          >
            {errorAlert}
          </SweetAlert>
        ) : null}

        {successAlert ? (
          <SweetAlert
            success
            title={"Enviado"}
            onConfirm={() => {
              setBtnLoading(false);
              window.location.reload(false);
            }}
          >
            Formulário enviado com sucesso
          </SweetAlert>
        ) : null}

        {step === "a" && (
          <Col md={8} style={{ width: "90%" }}>
            <Card>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitForm(e);
                    // uploadImg(e);
                  }}
                >
                  <Row style={{ marginTop: 10 }}>
                    <Col md={12}>
                      <label className="form-label">
                        Qual meio de transporte irá utilizar? *
                      </label>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="packaging"
                          id="transp1"
                          onChange={() => {
                            clearAll();
                            setOptionTransport("Carro Próprio");
                          }}
                        />
                        <label className="form-check-label" htmlFor="transp1">
                          Carro Próprio
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="packaging"
                          id="transp2"
                          onChange={() => {
                            clearAll();
                            setOptionTransport("Transfer");
                          }}
                        />
                        <label className="form-check-label" htmlFor="transp2">
                          Transfer
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="packaging"
                          id="transp3"
                          onChange={() => {
                            clearAll();
                            setOptionTransport("Outros");
                          }}
                        />
                        <label className="form-check-label" htmlFor="transp3">
                          Outros
                        </label>
                      </div>
                    </Col>
                  </Row>

                  {optionTransport === "Carro Próprio" && (
                    <>
                      <Row style={{ marginTop: 10 }}>
                        <label className="form-label">Marca *</label>
                        <Col md={12}>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Digite a marca"
                            onChange={(e) => setMarca(e.target.value)}
                            // value={carroP}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        <label className="form-label">Modelo *</label>
                        <Col md={12}>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Digite o modelo"
                            onChange={(e) => setModelo(e.target.value)}
                            // value={carroP}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        <label className="form-label">Placa *</label>
                        <Col md={12}>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Digite a placa"
                            onChange={(e) => setPlaca(e.target.value)}
                            // value={carroP}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {optionTransport === "Transfer" && (
                    <Row style={{ marginTop: 10 }}>
                      <label className="form-label">Ponto de Saída *</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Selecione"
                        isClearable={true}
                        isSearchable={true}
                        name="hotels"
                        options={hotels}
                        onChange={(selected, { action }) => {
                          if (action === "clear") {
                            setHotel("");
                            return;
                          }
                          setHotel(selected.value);
                        }}
                      />
                    </Row>
                  )}
                  {optionTransport === "Outros" && (
                    <Row style={{ marginTop: 10 }}>
                      <Col md={12}>
                        <label className="form-label">
                          Descreva o seu meio de transporte abaixo: *
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Digite o meio de transporte"
                          onChange={(e) => setOtherOption(e.target.value)}
                          // value={carroP}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row style={{ marginTop: 20 }}>
                    <Col md={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          disabled={btnLoading}
                          className="btn-submit"
                          type="submit"
                          style={{ backgroundColor: btnLoading ? "gray" : "#622140" }}
                        >
                          {btnLoading ? "Carregando" : "Enviar"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        )}

        {step === "b" && <CardQuestionAnswered />}
      </section>
    </div>
  );
}
