import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, CardTitle, CardBody, Row, Col, Button } from "reactstrap";
import "./styled.css";
//alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { postUploadFile } from "../../../services/uploadFile";
import { postSurvey } from "../../../services/survey";
import { createEarnedPoints } from "../../../services/ranking";

export default function IfoodEnquete1() {
  // dados do usuário por parâmetro
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();
  // opções do radio do formulário sobre embalagem
  const [optionPackaging, setOptionPackaging] = useState("");
  const [otherOptionPackaging, setOtherOptionPackaging] = useState("");
  // imagem a ser hospedada
  const [file, setFile] = useState();
  // alerta
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  // carregamento do botão
  const [btnLoading, setBtnLoading] = useState(false);

  function dateFormated(e) {
    var date = e.split("-");

    var newDate = date[2] + "/" + date[1] + "/" + date[0];

    return newDate;
  }

  async function uploadImg(e) {
    setBtnLoading(true);
    if (!e.target[0].value) {
      setErrorAlert('O campo "Nome do restaurante" é obrigatório');
      return;
    } else setErrorAlert("");

    if (!e.target[1].value) {
      setErrorAlert(
        'O campo "Culinária do restaurante (ex: Lanches, comida brasileira, vegana)" é obrigatório'
      );
      return;
    } else setErrorAlert("");

    if (!e.target[2].value) {
      setErrorAlert('O campo "Data do pedido" é obrigatório');
      return;
    } else setErrorAlert("");

    if (!optionPackaging) {
      setErrorAlert(
        'Seleciona uma opção para "Material da embalagem principal (aquela que embrulha o item principal do pedido)"'
      );
      return;
    } else setErrorAlert("");

    if (optionPackaging === "Outro" && !otherOptionPackaging) {
      setErrorAlert(
        'Insira outra opção para "Material da embalagem principal (aquela que embrulha o item principal do pedido)"'
      );
      return;
    } else setErrorAlert("");

    if (!file) {
      setErrorAlert(
        'O campo "Envie uma foto da embalagem principal do seu pedido" é obrigatório'
      );
      return;
    } else setErrorAlert("");

    var data = new FormData();

    data.append("files", file.target.files[0]);
    data.append("eventId", user.idEvent);
    data.append("loginId", user.idPart);
    data.append("folder", "video");

    await postUploadFile(data)
      .then((res) => {
        // console.log("img", res);
        submitForm(e, res.data[0].url);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar o formulário, tente novamente em instantes"
        )
      );
  }

  async function submitForm(e, img) {
    const question1 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Nome do restaurante",
      answer: e.target[0].value,
      hit: true,
    };
    await postSurvey(question1)
      .then((res) => {
        // console.log("reposta 1", res);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    const question2 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Culinária do restaurante",
      answer: e.target[1].value,
      hit: true,
    };
    await postSurvey(question2)
      .then((res) => {
        // console.log("reposta 2", res);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    const question3 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Data do pedido",
      answer: dateFormated(e.target[2].value),
      hit: true,
    };
    await postSurvey(question3)
      .then((res) => {
        // console.log("reposta 3", res);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    const question4 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Material da embalagem principal",
      answer:
        optionPackaging === "Outro" ? otherOptionPackaging : optionPackaging,
      hit: true,
    };
    await postSurvey(question4)
      .then((res) => {
        // console.log("reposta 4", res);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    const question5 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Envie uma foto da embalagem principal do seu pedido",
      answer: img,
      hit: true,
    };
    await postSurvey(question5)
      .then((res) => {
        // console.log("reposta 5", res);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    const dataRanking = {
      code: "DESAFIO1",
      positive: true,
      log: "Acertou enquete",
    };
    await createEarnedPoints(dataRanking, user.idEvent, user.idPart);
    // .then((res) => {
    //   console.log("pontos", res);
    // })
    // .catch((error) => console.log("erro pontos", error));

    setSuccessAlert(true);
  }

  useEffect(() => {
    if (window.location.search !== "") {
      setUser({
        idEvent: searchParams.get("idEvent"),
        idPart: searchParams.get("idPart"),
      });
    }
  }, []);

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#eb0033",
      }}
    >
      {errorAlert ? (
        <SweetAlert
          warning
          title={"Alerta!"}
          onConfirm={() => {
            setBtnLoading(false);
            setErrorAlert("");
          }}
        >
          {errorAlert}
        </SweetAlert>
      ) : null}

      {successAlert ? (
        <SweetAlert
          success
          title={"Enviado"}
          onConfirm={() => {
            setBtnLoading(false);
            window.location.reload(false);
          }}
        >
          Formulário enviado com sucesso
        </SweetAlert>
      ) : null}

      <Col md={7}>
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
                Sabemos que vocês amam tirar foto do pedido pelo grupo...
                pensando nisso, criamos o desafio Packlovers para vocês ganharem
                pontos no score de engajamento por isso!
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                A primeira resposta do dia nesse formulário vale 2 pontos no
                score de engajamento :)
              </Col>
            </Row>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                uploadImg(e);
              }}
            >
              <Row style={{ marginTop: 10 }}>
                <Col md={12}>
                  <label className="form-label">Nome do restaurante *</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Sua resposta"
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Col md={12}>
                  <label className="form-label">
                    Culinária do restaurante (ex: Lanches, comida brasileira,
                    vegana) *
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Sua resposta"
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Col md={12}>
                  <label className="form-label">Data do pedido *</label>
                  <input
                    className="form-control"
                    type="date"
                    placeholder="Sua resposta"
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Col md={12}>
                  <label className="form-label">
                    Material da embalagem principal (aquela que embrulha o item
                    principal do pedido) *
                  </label>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="packaging"
                      id="optionPackaging1"
                      onChange={() => {
                        setOptionPackaging("Papel");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optionPackaging1"
                    >
                      Papel
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="packaging"
                      id="optionPackaging2"
                      onChange={() => {
                        setOptionPackaging("Plástico");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optionPackaging2"
                    >
                      Plástico
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="packaging"
                      id="optionPackaging3"
                      onChange={() => {
                        setOptionPackaging("Isopor");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optionPackaging3"
                    >
                      Isopor
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="packaging"
                      id="optionPackaging4"
                      onChange={() => {
                        setOptionPackaging("Alumínio");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optionPackaging4"
                    >
                      Alumínio
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="packaging"
                      id="optionPackaging5"
                      onChange={() => {
                        setOptionPackaging("Vidro");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optionPackaging5"
                    >
                      Vidro
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="packaging"
                      id="optionPackaging6"
                      onChange={() => {
                        setOptionPackaging("Biodegradável");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optionPackaging6"
                    >
                      Biodegradável
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="packaging"
                      id="optionPackaging7"
                      onChange={() => {
                        setOptionPackaging("Não sei");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optionPackaging7"
                    >
                      Não sei
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="packaging"
                      id="optionPackaging8"
                      onChange={() => {
                        setOptionPackaging("Outro");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optionPackaging8"
                    >
                      Outro
                    </label>
                  </div>
                </Col>
              </Row>

              {optionPackaging === "Outro" && (
                <Row style={{ marginTop: 10 }}>
                  <Col md={12}>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Outra opção"
                      onChange={(e) => setOtherOptionPackaging(e.target.value)}
                      value={otherOptionPackaging}
                    />
                  </Col>
                </Row>
              )}

              <Row style={{ marginTop: 10 }}>
                <Col md={12}>
                  <label className="form-label">
                    Envie uma foto da embalagem principal do seu pedido *
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(e) => setFile(e)}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      disabled={btnLoading}
                      className="btn-submit"
                      type="submit"
                      style={{ backgroundColor: btnLoading && "gray" }}
                    >
                      {btnLoading ? "Carregando" : "Enviar"}
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
    </section>
  );
}
