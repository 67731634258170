import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import './styled.css';
//alerta
import SweetAlert from 'react-bootstrap-sweetalert';
// service
import { postSurvey, verifySurveyByUser } from '../../../services/survey';
import { createEarnedPoints } from '../../../services/ranking';

export default function IfoodPesquisa1() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  const [optionScore, setOptionScore] = useState('');
  const [optionContent, setOptionContent] = useState('');
  const [platform, setOptionPlatform] = useState('');
  const [comment, setComment] = useState('');
  const [subject, setSubject] = useState('');
  const [isAvailable, setIsAvailable] = useState(null);

  const [errorAlert, setErrorAlert] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  async function send(e) {
    setBtnLoading(true);
    if (platform === '') {
      setErrorAlert(
        'O campo "Em qual plataforma você assistiu o evento?" é obrigatório'
      );
      return;
    } else setErrorAlert('');

    if (optionScore === '') {
      setErrorAlert(
        `O campo "Em uma escala de 1 a 5 (sendo 1 "muito insatisfeito" e 5
        "muito satisfeito"), como você avalia o evento?" é obrigatório`
      );
      return;
    } else setErrorAlert('');

    if (optionContent === '') {
      setErrorAlert(`O campo "Em uma escala de 1 a 5 (sendo 1 "muito insatisfeito" e 5
      "muito satisfeito"), como você avalia o conteúdo
      apresentado?" é obrigatório`);
      return;
    } else setErrorAlert('');

    if (subject === '') {
      setErrorAlert(
        `O campo "Quais temas sobre o iFood você gostaria que trouxéssemos no próximo encontro?" é obrigatório`
      );
      return;
    } else setErrorAlert('');

    if (comment === '') {
      setErrorAlert(
        `O campo "Quer comentar algo? O que mais gostou, o que acha que precisa melhorar, o que você quiser..." é obrigatório`
      );
      return;
    } else setErrorAlert('');

    submitForm();
  }

  async function submitForm() {
    const question1 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: 'Em qual plataforma você assistiu o evento?',
      answer: platform,
      hit: true,
    };
    await postSurvey(question1)
      .then((res) => {
        // console.log("reposta 1", res);
      })
      .catch(() =>
        setErrorAlert(
          'Erro ao enviar as resposta do formulário, tente novamente em instantes'
        )
      );

    const question2 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: `Em uma escala de 1 a 5 (sendo 1 "muito insatisfeito" e 5 "muito satisfeito"), como você avalia o evento?`,
      answer: optionScore,
      hit: true,
    };
    await postSurvey(question2)
      .then((res) => {
        // console.log("reposta 2", res);
      })
      .catch(() =>
        setErrorAlert(
          'Erro ao enviar as resposta do formulário, tente novamente em instantes'
        )
      );

    const question3 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: `Em uma escala de 1 a 5 (sendo 1 "muito insatisfeito" e 5 "muito satisfeito"), como você avalia o conteúdo apresentado?`,
      answer: optionContent,
      hit: true,
    };
    await postSurvey(question3)
      .then((res) => {
        // console.log("reposta 3", res);
      })
      .catch(() =>
        setErrorAlert(
          'Erro ao enviar as resposta do formulário, tente novamente em instantes'
        )
      );

    const question4 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question:
        'Quais temas sobre o iFood você gostaria que trouxéssemos no próximo encontro?',
      answer: subject,
      hit: true,
    };
    await postSurvey(question4)
      .then((res) => {
        // console.log("reposta 4", res);
      })
      .catch(() =>
        setErrorAlert(
          'Erro ao enviar as resposta do formulário, tente novamente em instantes'
        )
      );

    const question5 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question:
        'Quer comentar algo? O que mais gostou, o que acha que precisa melhorar, o que você quiser...',
      answer: comment,
      hit: true,
    };
    await postSurvey(question5)
      .then((res) => {
        // console.log("reposta 5", res);
      })
      .catch(() =>
        setErrorAlert(
          'Erro ao enviar as resposta do formulário, tente novamente em instantes'
        )
      );

    const dataRanking = {
      code: 'PESQUISA4',
      positive: true,
      log: 'Acertou enquete',
    };
    await createEarnedPoints(dataRanking, user.idEvent, user.idPart)
      .then((res) => {
        // console.log('pontos', res);
      })
      .catch((error) => '');

    if (!successAlert) {
      setSuccessAlert(true);
    }
  }

  useEffect(() => {
    if (window.location.search !== '') {
      setUser({
        idEvent: searchParams.get('idEvent'),
        idPart: searchParams.get('idPart'),
      });

      checkIfSurveyIsAvailable(
        searchParams.get('idEvent'),
        searchParams.get('idPart')
      );
    }
  }, []);

  async function checkIfSurveyIsAvailable(event, login) {
    verifySurveyByUser({
      eventId: event,
      loginId: login,
      question: 'Em qual plataforma você assistiu o evento?',
    }).then((res) => {
      if (res.message === 'QUESTION_AVAILABLE') {
        setIsAvailable(true);
      } else {
        setIsAvailable(false);
      }
    });
  }

  return (
    <section className="container-card">
      {errorAlert ? (
        <SweetAlert
          warning
          title={'Alerta!'}
          onConfirm={() => {
            setBtnLoading(false);
            setErrorAlert('');
          }}
        >
          {errorAlert}
        </SweetAlert>
      ) : null}
      {successAlert ? (
        <SweetAlert
          success
          title={'Enviado'}
          onConfirm={() => {
            setBtnLoading(false);
            setSuccessAlert(false);
            window.location.reload(false);
          }}
        >
          Formulário enviado com sucesso
        </SweetAlert>
      ) : null}

      <Col md={7}>
        <Card>
          {isAvailable && isAvailable !== null ? (
            <CardBody>
              <Row style={{ fontWeight: 'bold', fontSize: '26px' }}>
                <Col md={12}>
                  Conta pra gente o que achou do nosso iFood Day!
                </Col>
              </Row>

              <Row style={{ marginTop: '16px' }}>
                <Col md={12}>
                  Curtiu nosso encontro? Acha que temos oportunidade de
                  melhorias?
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col md={12}>Conta pra gente o que achou!</Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col md={12}>
                  Com o seu feedback, poderemos identificar, evoluir cada vez os
                  nossos bate-papos e encontros. Vamos nessa?
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col md={12}>Valeu!</Col>
              </Row>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  send(e);
                }}
              >
                <Row style={{ marginTop: 24 }}>
                  <Col md={12}>
                    <label className="form-label">
                      Em qual plataforma você assistiu o evento? *
                    </label>
                    <div style={{ marginLeft: '24px' }}>
                      <div className="form-check mb-3 mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="platform"
                          id="opt-platform1"
                          onChange={() => {
                            setOptionPlatform('Youtube');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-platform1"
                        >
                          Youtube
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="platform"
                          id="opt-platform2"
                          onChange={() => {
                            setOptionPlatform('Zoom');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-platform2"
                        >
                          Zoom
                        </label>
                      </div>
                    </div>
                    {/* <input
                    className="form-control"
                    type="text"
                    placeholder="Sua resposta"
                  /> */}
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  <Col md={12}>
                    <label className="form-label">
                      Em uma escala de 1 a 5 (sendo 1 "muito insatisfeito" e 5
                      "muito satisfeito"), como você avalia o evento? *
                    </label>
                    <p>Muito insatisfeito</p>
                    <div style={{ marginLeft: '24px' }}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="score"
                          id="opt-score1"
                          onChange={() => {
                            setOptionScore('1');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-score1"
                          style={{ marginRight: '13px' }}
                        >
                          1
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="score"
                          id="opt-score2"
                          onChange={() => {
                            setOptionScore('2');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-score2"
                          style={{ marginRight: '13px' }}
                        >
                          2
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="score"
                          id="opt-score3"
                          onChange={() => {
                            setOptionScore('3');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-score3"
                          style={{ marginRight: '13px' }}
                        >
                          3
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="score"
                          id="opt-score4"
                          onChange={() => {
                            setOptionScore('4');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-score4"
                          style={{ marginRight: '13px' }}
                        >
                          4
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="score"
                          id="opt-score5"
                          onChange={() => {
                            setOptionScore('5');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-score5"
                          style={{ marginRight: '13px' }}
                        >
                          5
                        </label>
                      </div>
                    </div>
                    <p>Muito satisfeito</p>
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  <Col md={12}>
                    <label className="form-label">
                      Em uma escala de 1 a 5 (sendo 1 "muito insatisfeito" e 5
                      "muito satisfeito"), como você avalia o conteúdo
                      apresentado? *
                    </label>
                    <p>Muito insatisfeito</p>
                    <div style={{ marginLeft: '24px' }}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="content"
                          id="opt-score1"
                          onChange={() => {
                            setOptionContent('1');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-content1"
                          style={{ marginRight: '11px' }}
                        >
                          1
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="content"
                          id="opt-content2"
                          onChange={() => {
                            setOptionContent('2');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-content2"
                          style={{ marginRight: '11px' }}
                        >
                          2
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="content"
                          id="opt-content3"
                          onChange={() => {
                            setOptionContent('3');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-content3"
                          style={{ marginRight: '11px' }}
                        >
                          3
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="content"
                          id="opt-content4"
                          onChange={() => {
                            setOptionContent('4');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-content4"
                          style={{ marginRight: '11px' }}
                        >
                          4
                        </label>
                      </div>

                      <div
                        className="form-check mb-3"
                        style={{ marginRight: '5px' }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="content"
                          id="opt-content5"
                          onChange={() => {
                            setOptionContent('5');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="opt-content5"
                        >
                          5
                        </label>
                      </div>
                    </div>
                    <p>Muito satisfeito</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} style={{ marginTop: 12 }}>
                    <label className="form-label">
                      Quais temas sobre o iFood você gostaria que trouxéssemos
                      no próximo encontro? *
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Sua resposta"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label className="form-label">
                      Quer comentar algo? O que mais gostou, o que acha que
                      precisa melhorar, o que você quiser... *
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Sua resposta"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 20 }}>
                  <Col md={12}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <button
                        disabled={btnLoading}
                        className="btn-submit"
                        type="submit"
                        style={{ backgroundColor: btnLoading && 'gray' }}
                      >
                        {btnLoading ? 'Carregando' : 'Enviar'}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          ) : (
            isAvailable !== null && (
              <CardBody>
                <Row>
                  <div
                    style={{
                      textAlign: 'center',
                      height: '90vh',
                      paddingTop: '35vh',
                    }}
                  >
                    <h2>Parabéns!</h2>
                    <a>Você já respondeu essa pesquisa </a>
                    <i
                      className="mdi mdi-comment-check-outline"
                      style={{ color: 'green', fontSize: 20 }}
                    />
                  </div>
                </Row>
              </CardBody>
            )
          )}
        </Card>
      </Col>
    </section>
  );
}
