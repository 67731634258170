import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
// service
import { verifySurveyByUser } from "../../../services/survey";
//alerta
import SweetAlert from "react-bootstrap-sweetalert";
// componentes
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";

export default function IfoodEnquete4() {
  // dados do usuário por parâmetro
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();
  // alerta
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  // step
  const [step, setStep] = useState("");
  // respostas
  const [answers, setAnswers] = useState([]);

  function verifyUser() {
    verifySurveyByUser({
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Você já fez alguma compra de Mercado no iFood?",
    })
      .then((res) => {
        console.log("ok", res);
        // se ja respondeu
        if (res.message === "QUESTION_ANSWERED") {
          setStep("block");
        }
        // se ainda não respondeu
        if (res.message === "QUESTION_AVAILABLE") {
          setStep("a");
        }
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    if (window.location.search !== "") {
      setUser({
        idEvent: searchParams.get("idEvent"),
        idPart: searchParams.get("idPart"),
        namePart: searchParams.get("namePart"),
      });
    }
  }, []);

  useEffect(() => {
    if (user) verifyUser();
  }, [user]);

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#eb0033",
        minHeight: "100vh",
      }}
    >
      {errorAlert ? (
        <SweetAlert
          warning
          title={"Alerta!"}
          onConfirm={() => {
            setErrorAlert("");
          }}
        >
          {errorAlert}
        </SweetAlert>
      ) : null}

      {successAlert ? (
        <SweetAlert
          success
          title={"Enviado"}
          onConfirm={() => {
            window.location.reload(false);
          }}
        >
          Formulário enviado com sucesso
        </SweetAlert>
      ) : null}

      <Col md={7} style={{ display: "flex", alignItems: "center" }}>
        <Card style={{ width: "100%" }}>
          <CardBody>
            {step === "a" ||
            step === "b" ||
            step === "c" ||
            step === "d" ||
            step === "e" ? (
              <>
                <div>
                  <p onClick={() => console.log(answers)}>
                    Experiência de compra de Mercado no iFood
                  </p>

                  <p>
                    A resposta nesse formulário vale 4 pontos no score de
                    engajamento :)
                  </p>
                </div>

                {step === "a" && (
                  <Step1
                    setAnswers={setAnswers}
                    answer={answers}
                    setStep={setStep}
                    step={step}
                    user={user}
                  />
                )}
                {step === "b" && (
                  <Step2
                    setAnswers={setAnswers}
                    answer={answers}
                    setStep={setStep}
                    user={user}
                  />
                )}
                {step === "c" && (
                  <Step3
                    setAnswers={setAnswers}
                    answer={answers}
                    setStep={setStep}
                    user={user}
                  />
                )}
                {step === "d" && (
                  <Step4
                    setAnswers={setAnswers}
                    answer={answers}
                    setStep={setStep}
                    user={user}
                  />
                )}
                {step === "e" && (
                  <Step5
                    setAnswers={setAnswers}
                    answer={answers}
                    setStep={setStep}
                    user={user}
                  />
                )}
              </>
            ) : null}

            {step === "block" && (
              <>
                <div style={{ textAlign: "center" }}>
                  <h2>Parabéns!</h2>
                  <a>Você já respondeu essa pesquisa </a>
                  <i
                    className="mdi mdi-comment-check-outline"
                    style={{ color: "green", fontSize: 20 }}
                  />
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </section>
  );
}
