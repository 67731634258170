import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// componentes
import Login from "./login";
import InformationStart from "./informationStart";
import Information from "./information";
import ChooseProduct from "./chooseProduct";
import RawMaterial from "./rawMaterial";
import EnvironmentalMonitoring from "./environmentalMonitoring";
import QualityControl from "./qualityControl";
import Finish from "./finish";
// service
import { postSurvey, verifySurveyByUser } from "../../services/survey";
import { createEarnedPoints } from "../../services/ranking";
import { timeByUser } from "../../services/userTime";
import { updateUser } from "../../services/user";
// img
import Logo from "../../assets/images/neogen/logo-neogen2.png";
// respostas corretas
import {
  leitePoMaterias,
  leiteUhtMaterias,
  iogurteMaterias,
  queijoMaterias,
  leitePoMonitoramento,
  leiteUhtMonitoramento,
  iogurteMonitoramento,
  queijoMonitoramento,
  leitePoControle,
  leiteUhtControle,
  iogurteControle,
  queijoControle,
} from "./answerProducts";

export default function Neogen() {
  const [step, setStep] = useState();
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();
  // dados do form inicial
  const [formRegisterData, setFormRegisterData] = useState({});
  const [formNotesData, setFormNotesData] = useState([]);
  const [formSoluctionsData, setFormSoluctionData] = useState([]);
  // dados do form produto
  const [productSelected, setProductSelected] = useState("");
  // dados do form materias primas
  const [materialSelecteds, setMaterialSelecteds] = useState([]);
  // dados do form monitoramento ambiental
  const [ambientalSelecteds, setAmbientalSelecteds] = useState([]);
  // dados do form controle de qualidade
  const [controlSelecteds, setControlSelecteds] = useState([]);
  // respostas certas
  const [materialCorrect, setMaterialCorrect] = useState([]);
  const [ambientalCorrect, setAmbientalCorrect] = useState([]);
  const [controlCorrect, setControlCorrect] = useState([]);
  // carregamento cotão
  const [loading, setLoading] = useState(false);
  // tempo de resposta
  const [startTime, setStartTime] = useState(0);
  // cancelar o game
  const [cancelGame, setCancelGame] = useState(false);

  async function submitForm() {
    const endTime = new Date().getTime();

    sendToApi({
      question: "Empresa",
      answer: formRegisterData.empresa,
      hit: true,
    }).then(() =>
      sendToApi({
        question: "Cargo",
        answer: formRegisterData.cargo,
        hit: true,
      }).then(() =>
        sendToApi({
          question: "Cidade",
          answer: formRegisterData.cidade,
          hit: true,
        }).then(() =>
          sendToApi({
            question: "WhatsApp corporativo",
            answer: formRegisterData.whatsapp,
            hit: true,
          }).then(() => {
            var notes = [];

            formNotesData.map((note) =>
              notes.push(note.title + ": " + note.note)
            );

            sendToApi({
              question:
                "1. Coloque em ordem de prioridade para seu laboratório os seguintes pontos:",
              answer: notes.join(" | "),
              hit: true,
            }).then(() =>
              sendToApi({
                question:
                  "2. Quais soluções Neogen você tem interesse em ter mais informações?",
                answer:
                  formSoluctionsData.length > 0
                    ? formSoluctionsData.join(", ")
                    : "-",
                hit: true,
              }).then(() =>
                sendToApi({
                  question: "Selecione o produto",
                  answer: productSelected,
                  hit: true,
                }).then(() =>
                  verifyOptionsProductsSelected(
                    materialSelecteds,
                    materialCorrect,
                    "Recepção de materias primas"
                  ).then(() =>
                    verifyOptionsProductsSelected(
                      ambientalSelecteds,
                      ambientalCorrect,
                      "Monitoramento Ambiental"
                    ).then(() =>
                      verifyOptionsProductsSelected(
                        controlSelecteds,
                        controlCorrect,
                        "Controle de qualidade do produto acabado"
                      ).then(() => {
                        timeByUser({
                          eventId: user.idEvent,
                          loginId: user.idPart,
                          answerTime: endTime - startTime,
                        }).then((res) => {
                          if (res.message === "CREATED") {
                            updateUser({
                              eventosId: user.idEvent,
                              loginId: user.idPart,
                              empresa: formRegisterData.empresa,
                            }).then((resUpdate) => {
                              if (resUpdate.message === "updated") {
                                setStep(6);
                              }
                            });
                          }
                        });
                      })
                    )
                  )
                )
              )
            );
          })
        )
      )
    );
  }

  async function verifyOptionsProductsSelected(
    options,
    optionsCorrects,
    title
  ) {
    var corrects = [];
    var incorrects = [];
    var codesPoints = [];

    options.map((option) => {
      var search = optionsCorrects.filter((e) => e.title === option);

      if (search[0]) {
        corrects.push(option);
        codesPoints.push(search[0].code);
      } else incorrects.push(option);
    });

    for (var i = 0; i < codesPoints.length; i++) {
      await sendToRanking(codesPoints[i]);
    }

    await sendToApi({
      question: "[CERTAS] " + title,
      answer: corrects.length > 0 ? corrects.join(", ") : "-",
      hit: true,
    });

    await sendToApi({
      question: "[ERRADAS] " + title,
      answer: incorrects.length > 0 ? incorrects.join(", ") : "-",
      hit: false,
    }).then(() => {
      return title;
    });
  }

  async function sendToApi({ question, answer, hit }) {
    const data = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: question,
      answer: answer,
      hit: hit,
    };
    await postSurvey(data);
  }

  async function sendToRanking(code) {
    const dataRanking = {
      code: code,
      positive: true,
      log: "Acertou enquete",
    };
    await createEarnedPoints(dataRanking, user.idEvent, user.idPart);
  }

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user.idEvent && user.idPart) {
        verifySurveyByUser({
          eventId: user.idEvent,
          loginId: user.idPart,
          question: "Selecione o produto",
        })
          .then((res) => {
            if (res.message === "QUESTION_ANSWERED") {
              setStep(6);
            } else if (res.message === "QUESTION_AVAILABLE") {
              setStep(1);
            } else window.alert("Erro ao reconhecer usuário/evento");
          })
          .catch(() => {
            window.alert("Erro temporário, tente novamente mais tarde");
          });
      } else {
        setStep(0);
        setCancelGame(true);
      }
    }
  }, [user]);

  useEffect(() => {
    switch (productSelected) {
      case "Leite em pó":
        setMaterialCorrect(leitePoMaterias);
        setAmbientalCorrect(leitePoMonitoramento);
        setControlCorrect(leitePoControle);
        return;

      case "Leite UHT":
        setMaterialCorrect(leiteUhtMaterias);
        setAmbientalCorrect(leiteUhtMonitoramento);
        setControlCorrect(leiteUhtControle);
        return;

      case "Iogurte sem lactose":
        setMaterialCorrect(iogurteMaterias);
        setAmbientalCorrect(iogurteMonitoramento);
        setControlCorrect(iogurteControle);
        return;

      case "Queijo":
        setMaterialCorrect(queijoMaterias);
        setAmbientalCorrect(queijoMonitoramento);
        setControlCorrect(queijoControle);
        return;
    }
  }, [productSelected]);

  return (
    <>
      <header className="header-neogen">
        <img src={Logo} className="header-neogen-img" />
      </header>
      <section className="section-neogen">
        <div className="card-form">
          {!step && step != 0 && (
            <div style={{ textAlign: "center" }}>
              <a>Carregando ...</a>
            </div>
          )}
          {step === 0 && (
            <Login
              formRegisterData={formRegisterData}
              setFormRegisterData={setFormRegisterData}
              setStep={setStep}
              user={user}
              setUser={setUser}
            />
          )}
          {step === 1 && (
            <InformationStart
              setStep={setStep}
              formRegisterData={formRegisterData}
              setFormRegisterData={setFormRegisterData}
              formNotesData={formNotesData}
              setFormNotesData={setFormNotesData}
              formSoluctionsData={formSoluctionsData}
              setFormSoluctionData={setFormSoluctionData}
              user={user}
              cancelGame={cancelGame}
            />
          )}
          {step === 7 && (
            <Information setStep={setStep} cancelGame={cancelGame} />
          )}
          {step === 2 && (
            <ChooseProduct
              setStep={setStep}
              setProductSelected={setProductSelected}
              productSelected={productSelected}
              cancelGame={cancelGame}
            />
          )}
          {step === 3 && (
            <RawMaterial
              setStep={setStep}
              selecteds={materialSelecteds}
              setSelecteds={setMaterialSelecteds}
              total={materialCorrect.length}
              setStartTime={setStartTime}
              cancelGame={cancelGame}
            />
          )}
          {step === 4 && (
            <EnvironmentalMonitoring
              setStep={setStep}
              selecteds={ambientalSelecteds}
              setSelecteds={setAmbientalSelecteds}
              total={ambientalCorrect.length}
              cancelGame={cancelGame}
            />
          )}
          {step === 5 && (
            <QualityControl
              submitForm={submitForm}
              selecteds={controlSelecteds}
              setSelecteds={setControlSelecteds}
              productSelected={productSelected}
              loading={loading}
              setLoading={setLoading}
              total={controlCorrect.length}
              cancelGame={cancelGame}
            />
          )}
          {step === 6 && (
            <Finish
              user={user}
              materialSelecteds={materialSelecteds}
              ambientalSelecteds={ambientalSelecteds}
              controlSelecteds={controlSelecteds}
              materialCorrect={materialCorrect}
              ambientalCorrect={ambientalCorrect}
              controlCorrect={controlCorrect}
              productSelected={productSelected}
              cancelGame={cancelGame}
            />
          )}
        </div>
      </section>
    </>
  );
}
