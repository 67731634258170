import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../../avipam/styled.css";

//component
import BodyCard from "../../../components/bodyCard";
const alternatives = [
  { name: "Sim", isCorrect: true },
  { name: "Não", isCorrect: false },
]

export default function AvipamEnquete7() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);
  return (
    <div className="section-files">
      <div className="div-files">
        <BodyCard
          user={user}
          type={"Enquete 7"}
          fileName="Enquete7"
          title={"Você já enviou seu vídeo?"}
          alternatives={alternatives}
          description={[{ data: "" }]}
          id={"0f4d8c43-c395-437e-a10e-62428a342c8a"}
          button={"Enviar"}
          codeRanking="ENQUETE7-EXTERNO"
        />
      </div>
    </div>
  );
}
