import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) Digital Products and Platform",
    correct: false,
  },
  {
    option: "b) Data Analytics and IA",
    correct: true,
  },
  {
    option: "c) Tecnology consultancy and transformation",
    correct: false,
  },
  {
    option: "d) Digital Application Services",
    correct: false,
  },
  {
    option: "e) Application modernization",
    correct: false,
  },
];

export default function Question5(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question5: {
          title:
            "Qual oferta permite que as empresas obtenham informações e insights por meio de modelagem de dados, painéis de relatórios e tecnologias de visualização de dados amigáveis e intuitivas?",
          option: option.option,
          correct: option.correct,
          order: 5
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t(
          "Qual oferta permite que as empresas obtenham informações e insights por meio de modelagem de dados, painéis de relatórios e tecnologias de visualização de dados amigáveis e intuitivas?"
        )}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
