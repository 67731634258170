import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) 1, 2 e 5",
    correct: false,
  },
  {
    option: "b) 1, 2 e 4",
    correct: true,
  },
  {
    option: "c) 1, 3 e 4",
    correct: false,
  },
  {
    option: "d) Todos os itens acima",
    correct: false,
  },
  {
    option: "e) 1, 2 e 3",
    correct: false,
  },
];

export default function Question37(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question37: {
          title:
            "Quais itens da lista abaixo representam diferenciais de venda exclusivos da Stefanini?",
          option: option.option,
          correct: option.correct,
          order: 37,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30, textAlign: "center" }}>
      <h5 style={{ textAlign: "center" }}>
        {t(
          "Quais itens da lista abaixo representam diferenciais de venda exclusivos da Stefanini?"
        )}
      </h5>

      <p>
        {t(
          "1. Cultura familiar que fortalece a nossa abordagem pessoal e cocriativa"
        )}
      </p>
      <p>
        {t("2. Nascido digital e de tamanho perfeito para agilidade de IA")}
      </p>
      <p>{t("3. Maior fornecedor global de soluções DWS")}</p>
      <p>{t("4. Líder reconhecido no quesito qualidade de serviço")}</p>
      <p>
        {t(
          "5. Maior revendedor global de ferramentas de ITSM & Asset Management"
        )}
      </p>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
