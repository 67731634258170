import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, CardTitle, CardBody, Row, Col, Button } from "reactstrap";
//alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { postSurvey } from "../../../services/survey";
import { createEarnedPoints } from "../../../services/ranking";

export default function IfoodEnquete3() {
  // dados do usuário por parâmetro
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();
  // alerta
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  // carregamento do botão
  const [btnLoading, setBtnLoading] = useState(false);
  // dados do campo de checkbox
  const [checkData, setCheckData] = useState("");

  function addOptionsCheck(option) {
    if (checkData) {
      setCheckData(checkData + `${option}, `);
    } else setCheckData(`${option}, `);
  }

  function removeOptionCheck(option) {
    setCheckData(checkData.replace(`${option}, `, ""));
  }

  async function submitForm(e, img) {
    setBtnLoading(true);
    if (!e.target[0].value) {
      setErrorAlert('O campo "Conte-nos sua ideia" é obrigatório');
      return;
    } else setErrorAlert("");

    if (!checkData) {
      setErrorAlert('O campo "Assunto" é obrigatório');
      return;
    } else setErrorAlert("");

    const question1 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Conte-nos sua ideia (Feedback)",
      answer: e.target[0].value,
      hit: true,
    };
    await postSurvey(question1)
      .then((res) => {
        console.log("reposta 1", res);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    const question2 = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Assunto (Feedback)",
      answer: checkData,
      hit: true,
    };
    await postSurvey(question2)
      .then((res) => {
        console.log("reposta 2", res);
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    const dataRanking = {
      code: "DESAFIO3",
      positive: true,
      log: "Acertou enquete",
    };
    await createEarnedPoints(dataRanking, user.idEvent, user.idPart).then(
      (res) => {
        console.log("pontos", res);
      }
    );
    //   .catch((error) => console.log("erro pontos", error));

    setSuccessAlert(true);
  }

  useEffect(() => {
    if (window.location.search !== "") {
      setUser({
        idEvent: searchParams.get("idEvent"),
        idPart: searchParams.get("idPart"),
        namePart: searchParams.get("namePart"),
      });
    }
  }, []);

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#eb0033",
        minHeight: "100vh",
      }}
    >
      {errorAlert ? (
        <SweetAlert
          warning
          title={"Alerta!"}
          onConfirm={() => {
            setBtnLoading(false);
            setErrorAlert("");
          }}
        >
          {errorAlert}
        </SweetAlert>
      ) : null}

      {successAlert ? (
        <SweetAlert
          success
          title={"Enviado"}
          onConfirm={() => {
            setBtnLoading(false);
            window.location.reload(false);
          }}
        >
          Formulário enviado com sucesso
        </SweetAlert>
      ) : null}

      <Col md={7} style={{ display: "flex", alignItems: "center" }}>
        <Card>
          <CardBody>
            <div>
              <p>Olá, {user?.namePart ? user.namePart : "Usuário"}!</p>

              <p>
                Quer dar algum feedback sobre produtos ou serviços do iFood do
                que está legal, ou precisa melhorar? Chegou seu momento!
              </p>

              <p>
                Registre aqui seu feedback e 1 ponto na primeira resposta do
                dia!
              </p>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitForm(e);
              }}
            >
              <Row style={{ marginTop: 10 }}>
                <Col md={12}>
                  <label
                    className="form-label"
                    onClick={() => console.log(user)}
                  >
                    Conte-nos sua ideia *
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Sua resposta"
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Col md={12}>
                  <label className="form-label">Assunto *</label>

                  <Row>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject1"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Agrupamento");
                            } else removeOptionCheck("Agrupamento");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject1"
                        >
                          Agrupamento
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject2"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Atendimento");
                            } else removeOptionCheck("Atendimento");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject2"
                        >
                          Atendimento
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubjec3"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Avaliações");
                            } else removeOptionCheck("Avaliações");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubjec3"
                        >
                          Avaliações
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject4"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Beta Testers");
                            } else removeOptionCheck("Beta Testers");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject4"
                        >
                          Beta Testers
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject5"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Cancelamentos");
                            } else removeOptionCheck("Cancelamentos");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject5"
                        >
                          Cancelamentos
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject6"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Clube iFood");
                            } else removeOptionCheck("Clube iFood");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject6"
                        >
                          Clube iFood
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject7"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Comercial");
                            } else removeOptionCheck("Comercial");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject7"
                        >
                          Comercial
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject8"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Comunidades");
                            } else removeOptionCheck("Comunidades");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject8"
                        >
                          Comunidades
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject9"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("EMI");
                            } else removeOptionCheck("EMI");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject9"
                        >
                          EMI
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject10"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Entregadores/Entregas");
                            } else removeOptionCheck("Entregadores/Entregas");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject10"
                        >
                          Entregadores/Entregas
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject11"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Farmacia");
                            } else removeOptionCheck("Farmacia");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject11"
                        >
                          Farmacia
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject12"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Fraude no app");
                            } else removeOptionCheck("Fraude no app");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject12"
                        >
                          Fraude no app
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject13"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("iFood Day");
                            } else removeOptionCheck("iFood Day");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject13"
                        >
                          iFood Day
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject14"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Marketing");
                            } else removeOptionCheck("Marketing");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject14"
                        >
                          Marketing
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject15"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck(
                                "Melhoria para o app de Comunidades"
                              );
                            } else
                              removeOptionCheck(
                                "Melhoria para o app de Comunidades"
                              );
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject15"
                        >
                          Melhoria para o app de Comunidades
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject16"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Mercado");
                            } else removeOptionCheck("Mercado");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject16"
                        >
                          Mercado
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject17"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Pagamentos");
                            } else removeOptionCheck("Pagamentos");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject17"
                        >
                          Pagamentos
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject18"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Promos e Cupons");
                            } else removeOptionCheck("Promos e Cupons");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject18"
                        >
                          Promos e Cupons
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 10 }}>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject19"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Restaurantes");
                            } else removeOptionCheck("Restaurantes");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject19"
                        >
                          Restaurantes
                        </label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="subject"
                          id="optionSubject20"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addOptionsCheck("Usabilidade do app");
                            } else removeOptionCheck("Usabilidade do app");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="optionSubject20"
                        >
                          Usabilidade do app
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      disabled={btnLoading}
                      className="btn-submit"
                      type="submit"
                      style={{ backgroundColor: btnLoading && "gray" }}
                    >
                      {btnLoading ? "Carregando" : "Enviar"}
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
    </section>
  );
}
