import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) Machine Learning e curadoria",
    correct: false,
  },
  {
    option: "b) Identificação visual (avatar) randômica",
    correct: false,
  },
  {
    option:
      "c) Intuitividade e naturalidade em diálogos multi-idiomas (LLM), construção automatizada de jornadas solutivas (GenIA), facilidade de integração com outras tecnologias aceleradoras",
    correct: true,
  },
  {
    option:
      "d) Construção automática de catálogo de serviços e artefatos de base de conhecimento",
    correct: false,
  },
  {
    option: "e) Análise de sentimento do cliente",
    correct: false,
  },
];

export default function Question39(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question39: {
          title: "Qual a principal nova feature da solução SophieX?",
          option: option.option,
          correct: option.correct,
          order: 39,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t("Qual a principal nova feature da solução SophieX?")}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
