import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./style.css";

const questions = [
  {
    option:
      "a) Que tomamos nossas decisões com base em apenas nossa intuição e opinião pessoal;",
    correct: false,
  },
  {
    option:
      "b) Que não precisamos aprender e evoluir nosso ponto de vista constantemente, já que a jornada do nosso paciente é a mesma desde que a Boehringer Ingelheim foi fundada.",
    correct: false,
  },
  {
    option:
      "c) Relatórios e Números são usados apenas para usarmos em reuniões, já que não precisamos tomar decisões estruturadas.",
    correct: false,
  },
  {
    option:
      "d) Uma cultura de dados nos permite evoluir e apurar nosso olhar aos clientes e mercado, tomar decisões estruturadas, de forma a evoluirmos de forma sustentável como companhia. ",
    correct: true,
  },
];

export default function Question1(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question1: {
          title: "Ter uma Cultura Centrada em Dados significa:",
          option: option.option,
          correct: option.correct,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t("Ter uma Cultura Centrada em Dados significa:")}
      </h5>

      <div className="div-options-question-boe">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-boe ${
              optionSelected === e.option && "selected-option-question-boe"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
