import axios from "axios";

// const urlBase = `${process.env.REACT_APP_URL_BASE}/api-admin-mobile`;
const urlBase = `https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-mobile`;

function postSurvey(data) {
  var config = {
    method: "post",
    url: `${urlBase}/surveys`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function verifySurveyByUser(data) {
  var config = {
    method: "post",
    url: `${urlBase}/surveys/answered`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { postSurvey, verifySurveyByUser };
