import React, { useState, useEffect } from "react";
import "../styled.css";
import { Link, useSearchParams } from "react-router-dom";
// services
import { verifySurveyByUser } from "../../../services/survey";
// componentes
import Loading from "./loading";

export default function Petropolis() {
  // parametros e dados do usuário
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState(null);
  // carregamento
  const [loading, setLoading] = useState(true);
  // respondido
  const [blockPdv1, setBlockPdv1] = useState(false);
  const [blockPdv2, setBlockPdv2] = useState(false);
  const [blockPdv3, setBlockPdv3] = useState(false);
  const [blockPdv4, setBlockPdv4] = useState(false);
  const [blockPdv5, setBlockPdv5] = useState(false);
  const [blockPdv6, setBlockPdv6] = useState(false);

  function verifyAnswer(question, setState) {
    verifySurveyByUser({
      eventId: user.idEvent,
      loginId: user.idPart,
      question: question,
    }).then((res) => {
      if (res.message === "QUESTION_ANSWERED") {
        setState(true);
      }
    });
  }

  useEffect(() => {
    if (user) {
      verifyAnswer(
        "Quali Conveniência(Dia 1 - Loja de Conveniência)",
        setBlockPdv1
      );
      verifyAnswer("Quali Rota(Dia 1 - Boteco)", setBlockPdv2);
      verifyAnswer("Quali Rota(Dia 1 - Bar)", setBlockPdv3);
      verifyAnswer("Quali AS(Dia 1 - Mini Mercado)", setBlockPdv4);
      verifyAnswer("Quali AS(Dia 1 - Supermercado)", setBlockPdv5);
      verifyAnswer("Quali AS(Dia 1 - Atacado)", setBlockPdv6);
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [user]);

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    } else {
      alert("Erro ao indentificar o usuário");
    }
  }, []);

  return (
    <>
      <section className="section-card-petropolis">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Link
              to={!blockPdv1 && "/petropolis1/pdv1"}
              className={`option-pdv ${blockPdv1 ? "option-pdv-disabled" : ""}`}
              state={{ user: user }}
            >
              Loja de Conveniência
              {blockPdv1 && <i className="mdi mdi-check-bold icon-check" />}
            </Link>
            <Link
              to={!blockPdv2 && "/petropolis1/pdv2"}
              className={`option-pdv ${blockPdv2 ? "option-pdv-disabled" : ""}`}
              state={{ user: user }}
            >
              Boteco
              {blockPdv2 && <i className="mdi mdi-check-bold icon-check" />}
            </Link>
            <Link
              to={!blockPdv3 && "/petropolis1/pdv3"}
              className={`option-pdv ${blockPdv3 ? "option-pdv-disabled" : ""}`}
              state={{ user: user }}
            >
              Bar
              {blockPdv3 && <i className="mdi mdi-check-bold icon-check" />}
            </Link>
            <Link
              to={!blockPdv4 && "/petropolis1/pdv4"}
              className={`option-pdv ${blockPdv4 ? "option-pdv-disabled" : ""}`}
              state={{ user: user }}
            >
              Mini Mercado
              {blockPdv4 && <i className="mdi mdi-check-bold icon-check" />}
            </Link>
            <Link
              to={!blockPdv5 && "/petropolis1/pdv5"}
              className={`option-pdv ${blockPdv5 ? "option-pdv-disabled" : ""}`}
              state={{ user: user }}
            >
              Supermercado
              {blockPdv5 && <i className="mdi mdi-check-bold icon-check" />}
            </Link>
            <Link
              to={!blockPdv6 && "/petropolis1/pdv6"}
              className={`option-pdv ${blockPdv6 ? "option-pdv-disabled" : ""}`}
              state={{ user: user }}
            >
              Atacado
              {blockPdv6 && <i className="mdi mdi-check-bold icon-check" />}
            </Link>
          </>
        )}
      </section>
    </>
  );
}
