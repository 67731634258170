import React from "react";

export default function Question(props) {
  const { title, optionSelected, setOptionSelected } = props;
  return (
    <>
      <div className="card-question">
        <a style={{ fontSize: 17, fontWeight: "bold" }}>{title}</a>
        <div className="div-avaliation-petropolis">
          {[...Array(Math.ceil(10))].map((e, i) => (
            <a
              key={i}
              className={`option-avaliation ${
                optionSelected == i + 1 ? "option-avaliation-selected" : ""
              } `}
              onClick={() => setOptionSelected(i + 1)}
            >
              {i + 1}
            </a>
          ))}
        </div>
      </div>
    </>
  );
}
