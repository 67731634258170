import React, { useState, useEffect } from "react";
import "./styled.css";

export default function PageAnswered() {
  return (
    <div
      style={{ textAlign: "center", width: 250, backgroundColor: "whitesmoke" }}
      className="card-question-kenvue"
    >
      <div style={{ textAlign: "center" }}>
        <h2>Parabéns!</h2>
        <a>Você já respondeu o Quiz</a>
        <i
          className="mdi mdi-comment-check-outline"
          style={{ color: "green", fontSize: 20, marginLeft: 10 }}
        />
      </div>
    </div>
  );
}
