import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const QuizContext = React.createContext({});

function QuizProvider(props) {
  const [currentContext, setNewContextRefresh] = useState(Date.now());

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getParamsUrl();
  }, [currentContext]);

  function getParamsUrl() {
    const params = new URLSearchParams(window.location.search);
    setIdiom(params.get("idioma"));
  }

  function setIdiom(idiom) {
    const lang =
      idiom === "pt-br" || idiom === "ptBr" || idiom?.toLowerCase() === "pt"
        ? "ptBr"
        : idiom === "sp" || idiom === "es"
        ? "es"
        : "en";
    i18n.changeLanguage(lang);
  }

  return (
    <QuizContext.Provider
      value={{
        setNewContextRefresh,
        t,
        i18n,
      }}
    >
      {props.children}
    </QuizContext.Provider>
  );
}

export default QuizProvider;
