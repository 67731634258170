import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option:
      "a) Reforçar o compromisso de desdobrar a estratégia de desenvolvimento sustentável da Boehringer Ingelheim, o SD4G.",
    correct: false,
  },
  {
    option: `b) Ao planejar um evento, seguir as etapas dos 5"R's" de: REPENSAR, RECUSAR, REDUZIR, REUTILIZAR e por fim RECICLAR.`,
    correct: false,
  },
  {
    option:
      "c) Estar alinhado ao nosso objetivo global de reduzir a pegada ambiental da empresa pela metade até 2030 atendendo aos Objetivos de Desenvolvimento Sustentável (ODS) da Organização das Nações Unidas (ONU).",
    correct: false,
  },
  {
    option:
      "d) Recusar o uso de Plástico único em todos os eventos da empresa, inclusive mini meetings, e buscar alternativas junto aos fornecedores.",
    correct: false,
  },
  {
    option: "e) Todas as alternativas acima.",
    correct: true,
  },
];

export default function Question9(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question9: {
          title: "EVENTO REGENERATIVO significa:",
          option: option.option,
          correct: option.correct,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>EVENTO REGENERATIVO significa:</h5>

      <div className="div-options-question-boe">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-boe ${
              optionSelected === e.option && "selected-option-question-boe"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
