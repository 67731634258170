import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option:
      "a) Growth & Performance; Mensuração e otimização de MROI; Martech & Analytics; Estratégia e inteligência de Marca",
    correct: false,
  },
  {
    option:
      "b) Conteúdo & Mídia; Comportamento do consumidor & insights ágeis; Customer experience & design; Estratégia e inteligência de Marca",
    correct: false,
  },
  {
    option:
      "c) Estratégia e inteligência de Marca; Conteúdo & Mídia; LTV & Loyalty; Mensuração e otimização de MROI",
    correct: false,
  },
  {
    option:
      "d) LTV & Loyalty; Growth & Performance; Martech & Analytics; Conteúdo & Mídia",
    correct: false,
  },
  {
    option:
      "e) Conteúdo & Mídia; Comportamento do consumidor & insights ágeis; Customer experience & design; Growth & Performance",
    correct: true,
  },
];

export default function Question7(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question7: {
          title:
            "Dados os principais desafios do marketing, quais ofertas devem ser destacadas numa prospecção?",
          option: option.option,
          correct: option.correct,
          order: 7
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t(
          "Dados os principais desafios do marketing, quais ofertas devem ser destacadas numa prospecção?"
        )}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
