import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) Redes sociais e sistemas de entretenimento",
    correct: false,
  },
  {
    option: "b) Sistemas de ar condicionado e iluminação",
    correct: false,
  },
  {
    option: "c) Sistemas de segurança e controle de acesso",
    correct: false,
  },
  {
    option: "d) Balanças, portões, ERP e sistemas de automação",
    correct: true,
  },
  {
    option: "e) Sistemas de comunicação interna da empresa",
    correct: false,
  },
];

export default function Question13(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question13: {
          title:
            "Quais sistemas a SAI Smart Logistics conecta para orquestrar o processo logístico?",
          option: option.option,
          correct: option.correct,
          order: 13
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t(
          "Quais sistemas a SAI Smart Logistics conecta para orquestrar o processo logístico?"
        )}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
