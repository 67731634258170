import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function Step1(props) {
  const [alert, setAlert] = useState("");

  function submitForm(e) {
    if (!e.target[0].checked && !e.target[1].checked) {
      setAlert("Selecione uma opção");
      return;
    }
    props.setAnswers([
      ...props.answer,
      {
        eventId: props.user.idEvent,
        loginId: props.user.idPart,
        question: "Você já fez alguma compra de Mercado no iFood?",
        answer: e.target[0].checked ? "Sim" : "Não",
        hit: true,
        questionOrder: 1,
      },
    ]);

    if (e.target[0].checked) {
      props.setStep("e");
    } else props.setStep("b");
  }

  // se ja foi respondido (retornar a essa pergunta), é descartado para uma nova resposta
  useEffect(() => {
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 1));
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitForm(e);
      }}
    >
      {alert ? (
        <SweetAlert
          warning
          title={"Campo(s) vazio(s)"}
          onConfirm={() => {
            setAlert("");
          }}
        >
          {alert}
        </SweetAlert>
      ) : null}
      <label className="form-label">
        1. Você já fez alguma compra de Mercado no iFood? *
      </label>
      <div>
        <input
          type="radio"
          name="chat"
          value="link"
          style={{ marginRight: 5 }}
          id="chatOption1"
        />
        <label htmlFor="chatOption1" className="form-label">
          Sim
        </label>
      </div>
      <div>
        <input
          type="radio"
          name="chat"
          value="link"
          style={{ marginRight: 5 }}
          id="chatOption2"
        />
        <label htmlFor="chatOption2" className="form-label">
          Não
        </label>
      </div>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="btn-submit" type="submit">
              Próximo
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
}
