import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { createUser, signIn } from "../../services/user";
// imagem
import Cadeia from "../../assets/images/neogen/CadeiaCompleta.jpg";

export default function Login(props) {
  const { formRegisterData, setFormRegisterData, setStep, user, setUser } =
    props;
  const [warningAlert, setWarningAlert] = useState("");

  function validateEmail(email) {
    const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return pattern.test(email);
  }

  async function nextStep() {
    const { email, nome } = formRegisterData;

    if (!email || !nome) {
      setWarningAlert("Para continuar preencha todos os campos");
      return;
    }

    if (!validateEmail(email)) {
      setWarningAlert("Formato do email inválido.");
      return;
    }

    await signIn(user.idEvent, email).then((res) => {
      if (res?.data?.data?.id) {
        setUser({
          idEvent: user.idEvent,
          idPart: res.data.data.id,
        });
      }

      if (res.message === "NOT_FOUND") {
        createUser({
          email: email,
          nome: nome,
          senha: "",
          foto: "",
          titleQrCode: "",
          codeQrCode: "",
          infoAcess: "",
          idEvento: user.idEvent,
          grupos: [],
          tokenNotification: "random",
          bundleId: null,
          cpf: "",
          empresa: "",
          cargo: "",
          interesseChat: "",
          codigoInterno1: "",
          codigoInterno2: "",
          codigoInterno3: "",
        }).then(() => {
          signIn(user.idEvent, email).then((resSigin) => {
            if (resSigin.data.data.id) {
              setUser({
                idEvent: user.idEvent,
                idPart: resSigin.data.data.id,
              });
            }
          });
        });
      }
    });

    return;

    // setStep(1);
  }

  return (
    <>
      {warningAlert && (
        <SweetAlert
          warning
          title="Atenção"
          onConfirm={() => {
            setWarningAlert("");
          }}
        >
          {warningAlert}
        </SweetAlert>
      )}
      <p className="p-about-neogen">
        <a>Bem vindo ao Laticínio Neogen!</a>
        <a>
          Antes de começar nosso game, precisamos de algumas informações
          cadastrais:
        </a>
      </p>

      <div className="div-imgs-process">
        <img src={Cadeia} style={{ width: "90%" }} />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          nextStep();
        }}
      >
        <Row>
          <Col md={6}>
            <label className="form-label">Nome</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setFormRegisterData((state) => {
                  return {
                    ...state,
                    nome: e.target.value,
                  };
                });
              }}
            />
          </Col>
          <Col md={6}>
            <label className="form-label">E-mail corporativo</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setFormRegisterData((state) => {
                  return {
                    ...state,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="div-btn-submit">
              <Button className="btn-success" type="submit">
                Próximo
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
}
