import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// img
import PlacasRapidas from "../../assets/images/neogen/placasRapidas.png";
import Placas from "../../assets/images/neogen/placas.png";
import MeiosCultura from "../../assets/images/neogen/meiosCultura.jpg";
import ColetaAmostras from "../../assets/images/neogen/coletaAmostras.jpg";
import Reveal from "../../assets/images/neogen/reveal.jpg";
import Leitora from "../../assets/images/neogen/leitora.png";
import Mds from "../../assets/images/neogen/mds.png";
import Testes from "../../assets/images/neogen/testes.jpg";
import Mls from "../../assets/images/neogen/mls.jpg";
import Alergenos from "../../assets/images/neogen/alergenos.jpg";
import Nutricionais from "../../assets/images/neogen/nutricionais.jpg";
import Soleris from "../../assets/images/neogen/soleris.jpg";
import Higiene from "../../assets/images/neogen/higiene.png";

import Controle from "../../assets/images/neogen/controle.png";

export default function QualityControl(props) {
  const {
    submitForm,
    selecteds,
    setSelecteds,
    loading,
    setLoading,
    total,
    cancelGame,
  } = props;
  const [warningAlert, setWarningAlert] = useState("");

  const data = [
    {
      img: PlacasRapidas,
      title: "Análise de Indicadores – Placas Rápidas Petrifilm",
    },
    {
      img: Placas,
      title: "Análise de Indicadores – Placas Petrifilm Padrão",
    },
    {
      img: MeiosCultura,
      title: "Análise de Indicadores e patógenos – Meios de cultura",
    },
    {
      img: ColetaAmostras,
      title: "Coleta de amostras (sacos, esponjas, swabs)",
    },
    {
      img: Reveal,
      title: "Detecção de Patógenos – Reveal 2.0",
    },
    {
      img: Leitora,
      title:
        "Análise de Indicadores – Leitora Petrifilm para automatizar a leitura das placas",
    },
    {
      img: Mds,
      title: "Detecção de Patógenos – MDS (Sistema de Detecção Molecular)",
    },
    {
      img: Testes,
      title: "Testes de Micotoxinas (Lateral Flow, Elisa)",
    },
    {
      img: Mls,
      title: "Análise rápida de produtos UHT – MLS",
    },
    {
      img: Alergenos,
      title: "Testes de Alergênicos (Lateral Flow, Elisa)",
    },
    {
      img: Nutricionais,
      title: "Análises Nutricionais - Megazyme",
    },
    {
      img: Soleris,
      title: "Testes automatizados para indicadores – Soleris",
    },
    {
      img: Higiene,
      title: "Monitoramento de Higiene por ATP – Clean-Trace, Accupoint",
    },
  ];

  function selectOption(option, id) {
    var selectedsData = selecteds;
    const search = selecteds.filter((e) => e === option);

    if (search[0]) {
      var selectedsData = selectedsData.filter((e) => e !== option);
    } else {
      if (selectedsData.length >= total) {
        setWarningAlert("Você já selecionou a quantidade máxima de itens");

        document.getElementById(id).checked = false;
        return;
      }

      selectedsData.push(option);
    }
    setSelecteds(selectedsData);
  }

  function nextStep() {
    if (selecteds.length < 1) {
      setWarningAlert("Selecione ao menos um item");
      setLoading(false);
      return;
    }

    if (!loading) {
      setLoading(true);
      submitForm();
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {warningAlert && (
        <SweetAlert
          warning
          title="Atenção"
          onConfirm={() => {
            setWarningAlert("");
          }}
        >
          {warningAlert}
        </SweetAlert>
      )}
      <a className="title-material">
        Chegamos na última etapa, controle de qualidade do produto acabado.
      </a>

      <p className="p-about-neogen">
        <a>
          Selecione abaixo todos os portfólios Neogen que atendem as
          necessidades de análises na etapa de controle de qualidade de produto
          acabado:
        </a>
      </p>

      <div className="div-imgs-process">
        <img src={Controle} className="img-process" />
      </div>

      <div className="div-all-material">
        {data.map((e, i) => (
          <label key={i} className="div-material" htmlFor={`id${e.title}`}>
            <img src={e.img} className="img-material" />
            <a className="name-material">{e.title}</a>
            <input
              style={{ marginTop: 10 }}
              type="checkbox"
              id={`id${e.title}`}
              onChange={() => selectOption(e.title, `id${e.title}`)}
            />
          </label>
        ))}
      </div>

      <Row>
        <Col md={12}>
          <div className="div-btn-submit">
            {cancelGame && (
              <Button
                className="btn-danger"
                style={{ marginRight: 20 }}
                onClick={() => window.location.reload()}
              >
                Voltar ao início
              </Button>
            )}
            <Button
              className="btn-success"
              onClick={() => {
                nextStep();
              }}
            >
              {loading ? "Carregando" : "Enviar"}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
