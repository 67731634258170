import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// componentes
import StefaniniEnquete1Questions from './questions';
import CardQuestionAnswered from './cardQuestionAnswered';
// services
import { verifySurveyByUser } from '../../../services/survey';

export default function StefaniniEnquete1() {
  // parametros e dados do usuário
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState(null);
  const [step, setStep] = useState(null);

  useEffect(() => {
    if (user) {
      verifySurveyByUser({
        eventId: user.idEvent,
        loginId: user.idPart,
        question: 'Ter uma Cultura Centrada em Dados significa:',
      }).then((res) => {
        console.log('ok', res);
        if (res.message === 'QUESTION_ANSWERED') {
          setStep('b');
        } else if (res.message === 'QUESTION_AVAILABLE') {
          setStep('a');
        } else return;
      });
    }
  }, [user]);

  useEffect(() => {
    if (window.location.search !== '') {
      const idEvent = searchParams.get('idEvent');
      const idPart = searchParams.get('idPart');

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);
  return (
    <>
      {step === 'a' && <StefaniniEnquete1Questions user={user} />}
      {step === 'b' && <CardQuestionAnswered />}
    </>
  );
}
