import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../../avipam/styled.css";

//component
import BodyCard from "../../../components/bodyCard";

const alternatives = [
  { name: "Movida e Localiza", isCorrect: true },
  { name: "Shift e Five", isCorrect: false },
  { name: "Eurocar e Unidas", isCorrect: false },
  { name: "Turbi e Uber", isCorrect: false },
]

export default function AvipamEnquete4() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);
  return (
    <div className="section-files">
      <div className="div-files">
        <BodyCard
          user={user}
          type={"Enquete 4"}
          fileName="Enquete4"
          title={"Quais os 2 fornecedores preferenciais de Locação de Carros (Mobilidade)?"}
          alternatives={alternatives}
          description={[{ data: "" }]}
          id={"1e053404-1fed-4c42-a56a-61a90dac1bd9"}
          button={"Enviar"}
          codeRanking="ENQUETE4-EXTERNO"
        />
      </div>
    </div>
  );
}
