import React from "react";

export default function Loading() {
  return (
    <div className="div-loading">
      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
      <a>Carregando</a>
    </div>
  );
}
