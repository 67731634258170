import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./style.css";

const questions = [
  {
    option: "a)  Qualquer pessoa, desde que se cadastre no programa",
    correct: false,
  },
  {
    option:
      "b) Apenas os pacientes que forem indicados pelos médicos do meu painel de visitação",
    correct: false,
  },
  {
    option:
      "c) Pacientes com prescrição médica de um dos produtos da Boehringer que são participantes do Programa Abraçar a Vida",
    correct: true,
  },
  {
    option:
      "d) O programa é destinado somente para pessoas que receberem indicação dos balconistas nas redes de farmácias participantes",
    correct: false,
  },
];

export default function Question1(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question1: {
          title: "Quem pode ter acesso ao Abraçar a Vida e seus benefícios?",
          option: option.option,
          correct: option.correct,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t("Quem pode ter acesso ao Abraçar a Vida e seus benefícios?")}
      </h5>

      <div className="div-options-question-boe">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-boe ${
              optionSelected === e.option && "selected-option-question-boe"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
