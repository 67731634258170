import axios from "axios";

async function createUser(data) {
  var config = {
    method: "post",
    url: `https://gateway-mobile-plus.sistemasinteegra.com.br/global/credentials`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function updateUser(data) {
  var config = {
    method: "put",
    url: `https://gateway-mobile-plus.sistemasinteegra.com.br/global/credentials/loginevents`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function signIn(eventoId, email) {
  const data = JSON.stringify({ email, eventoId });
  const config = {
    method: "post",
    url: `https://gateway-mobile-plus.sistemasinteegra.com.br/api-eventos-mobile/login/loginbyemail`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return { status: response.status, data: response.data };
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { createUser, updateUser, signIn };
