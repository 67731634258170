export const etica = [
  {
    type: "Ética & Compliance",
    title: "É correto afirmar que a Linha de Integridade da Kenvue:",
    subtitle: (
      <div className="title-question-kenvue">
        <a>I - Esta disponível 24 horas por dia, 7 dias na semana.</a>
        <a>II - Está disponível para qualquer pessoa.</a>
        <a>III - Está disponível em vários idiomas.</a>
        <a>IV - Denúncias anônimas são aceitáveis.</a>
      </div>
    ),
    options: [
      {
        response: "A) Afirmações I e IV estão corretas.",
        correct: false,
      },
      {
        response: "B) Afirmações I e II e III estão corretas.",
        correct: false,
      },
      {
        response: "C) Todas as alternativas estão corretas.",
        correct: true,
      },
    ],
  },

  {
    type: "Ética & Compliance",
    title:
      "Conflitos de interesses ocorrem quando os interesses pessoais de um indivíduo podem entrar em conflito com o julgamento, as ações ou as decisões deste indivíduo no local de trabalho. Escolha as melhores práticas:",

    subtitle: (
      <div className="title-question-kenvue">
        <a>
          I - Os Kenvuers devem divulgar de forma proativa e imediata os
          conflitos de interesses.
        </a>
        <a>
          II - Os Kenvuers podem receber brindes dos terceiros desde que o
          gestor direto também receba o mesmo item e esteja dentro do valor
          limite permitido para brindes.{" "}
        </a>
        <a>
          III - Os Kenvuers devem reportar os potenciais conflitos de interesse
          para seus gestores, RH, Legal ou Ética & Compliance.{" "}
        </a>
        <a>
          IV - Os Kenvuers podem pagar por refeições ou hospitalidade
          ocasionalmente para Profissionais da Saúde, desde que atrelados à
          atividades de cunho educacional e/ou promocional em troca de
          prescrição.{" "}
        </a>
      </div>
    ),
    options: [
      {
        response: "A) Afirmações I e III estão corretas.",
        correct: true,
      },
      {
        response: "B) Afirmações I e IV estão corretas.",
        correct: false,
      },
      {
        response: "C) Todas as alternativas estão corretas.",
        correct: false,
      },
    ],
  },

  {
    type: "Ética & Compliance",
    title:
      "Os líderes de pessoas tem a responsabilidade de liderar pelo exemplo, orientar os Kenvuers para analisar e entender as políticas da Empresa e abordar adequadamente quaisquer possíveis violações. Portanto, todos os Kenvuers tem a oportunidade de liderar os outros por meio dos seus pensamentos e ações éticas, incluindo seus gestores diretos.",
    options: [
      {
        response: "A) Verdadeiro",
        correct: true,
      },
      {
        response: "B) Falso",
        correct: false,
      },
    ],
  },

  {
    type: "Ética & Compliance",
    title:
      "Quais afirmações melhores descrevem a atuação da área de Ética & Compliance da Kenvue:",
    subtitle: (
      <div className="title-question-kenvue">
        <a>
          I - Interações com Profissionais da Saúde em atividades educacionais
          e/ou promocionais.
        </a>
        <a>II - Ética e Integridade.</a>
        <a>III - Conflito de Interesse.</a>
        <a>IV - TPI Due Diligence.</a>
        <a>V - Cartão Coorporativo.</a>
      </div>
    ),
    options: [
      {
        response: "A) Afirmações I e II estão corretas.",
        correct: false,
      },
      {
        response: "B) Afirmações I e III estão corretas.",
        correct: false,
      },
      {
        response: "C) Afirmações I, II, III e IV estão corretas.",
        correct: true,
      },
      {
        response: "D) Todas as alternativas estão corretas. ",
        correct: false,
      },
    ],
  },

  {
    type: "Ética & Compliance",
    title:
      "Quais os problemas relacionados à superestocagem de canal (selecione as alternativas aplicáveis)?",
    options: [
      {
        response: "A) Gargalo nas entregas, podendo resultar em atrasos.",
        correct: false,
      },
      {
        response:
          "B) Competição injusta frente a outros competidores da Kenvue.",
        correct: false,
      },
      {
        response:
          "C) Incremento temporário da receita e margem nas demonstrações financeiras.",
        correct: true,
      },
      {
        response:
          "D) Não existe qualquer problema em superestocar os clientes.",
        correct: false,
      },
    ],
  },

  {
    type: "Ética & Compliance",
    title:
      "Em relação a precificação dos nossos clientes, o que a Kenvue pode fazer (selecione as alternativas aplicáveis)?",
    options: [
      {
        response:
          "A) Utilizar verbas comerciais e / ou outros incentivos para garantir preço fixo na gôndola do cliente.",
        correct: false,
      },
      {
        response:
          "B) Recomendar faixas de preço na gôndola desde que isso não signifique exercer pressão sobre o cliente.",
        correct: true,
      },
      {
        response:
          "C) Discutir com distribuidores os detalhes de preço de outros clientes com o objetivo de alavancar vendas.",
        correct: false,
      },
      {
        response:
          "D) Fixar um preço mínimo de revenda dos nossos produtos, visando proteger o GP da companhia.",
        correct: false,
      },
    ],
  },
];

export const techData = [
  {
    type: "Tech & Data",
    title:
      "Recebi os dados do novo contato comercial de um cliente e preciso enviar para minha equipe. O e-mail contém Nome, cargo e o nome da empresa (cliente).",
    subtitle: <h4>Assinale a alternativa correta:</h4>,
    options: [
      {
        response:
          "A) Não posso enviar esses dados para minha equipe por conter informações confidenciais",
        correct: false,
      },
      {
        response:
          "B) Não posso enviar para minha equipe através do meu email Kenvue, mesmo com criptografia",
        correct: false,
      },
      {
        response:
          "C) Devo direcionar para o meu email pessoal já que são informações confidenciais",
        correct: false,
      },
      {
        response:
          "D) Por serem dados confidenciais apenas, posso enviar normalmente pelo meu email Kenvue",
        correct: true,
      },
      {
        response:
          "E) Qualquer forma de envio citadas nas alternativas c ou d são válidas",
        correct: false,
      },
    ],
  },

  {
    type: "Tech & Data",
    title:
      "Sou um novo funcionário Kenvue e percebi que existem muitas oportunidades para automatizar dados  recebidos de várias fontes diferentes. O que devo fazer?",
    options: [
      {
        response:
          "A) Por ser um experimento, posso fazer download de um software de licença free na internet e iniciar o meu trabalho",
        correct: false,
      },
      {
        response:
          "B) Por ser um experimento, vou enviar os arquivos para um amigo que conhece bastante desse assunto e pedir ajuda",
        correct: false,
      },
      {
        response:
          "C) Como eu não tenho certeza do que pode ser utilizado e quais os riscos para as informações que eu tenho em posse, vou solicitar ajuda do time de IT",
        correct: true,
      },
      {
        response:
          "D) Para reduzir custos, acredito que fazer a compra de um software via internet com o meu cartão corporativo pode ser a melhor solução",
        correct: false,
      },
      {
        response: "E) nenhuma das alternativas anteriores está correta",
        correct: false,
      },
    ],
  },

  {
    type: "Tech & Data",
    title:
      "Minha equipe está trabalhando em um projeto que utiliza dados de perfis de consumidores de e-commerce, podendo portanto conter informações de CPF, etnia, localização geográfica entre outros.  Como são muitos dados, estou pensando em algumas alternativas:",
    options: [
      {
        response:
          "A) Vou solicitar que seja enviado para o meu email pessoal, assim consigo gravar no meu computador pessoal que é bem mais rápido e ninguem tem acesso",
        correct: false,
      },
      {
        response:
          "B) Como são dados altamente restritos, preciso assegurar que o armazenamento seja feito somente em tecnologias aprovadas pela Kenvue: One Drive, Share Point ou MBOX/SDX",
        correct: true,
      },
      {
        response:
          "C) Posso gravar em um HD externo, colocando uma senha no arquivo, assim tenho mais flexibilidade de acesso e mais agilidade no manuseio dos dados",
        correct: false,
      },
      {
        response:
          "D) Como o risco é baixo, posso gravar os dados localmente no meu dispositivo Kenvue e salvar uma cópia em um HD externo sempre que eu precisar compartilhar essa informação",
        correct: false,
      },
      {
        response: "E) As alternativas A e C estão corretas",
        correct: false,
      },
    ],
  },

  {
    type: "Tech & Data",
    title:
      "Teremos o lançamento de uma nova campanha promocional e estamos com os prazos muito apertados. Para ativar a campanha, estamos buscando um novo fornecedor para  o desenvolvimento do website da campanha, o que preciso fazer?",
    options: [
      {
        response:
          "A) Entrar em contato com o time de IT para que as devidas validações sejam realizadas e time de Procurement envolvido",
        correct: true,
      },
      {
        response:
          "B) Como o prazo está muito próximo, posso entrar em contato com o fornecedor diretamente e criar o pedido de compra",
        correct: false,
      },
      {
        response:
          "C) Como o fornecedor já está cadastrado para realizar a campanha, posso seguir com a criação do website sem envolver o time de IT ou procurement",
        correct: false,
      },
      {
        response:
          "D) Posso fazer download de alguma ferramenta gratuita na internet que nos ajude no desenvolvimento do Website",
        correct: false,
      },
      {
        response: "E) As alternativas c ou d estão corretas",
        correct: false,
      },
    ],
  },
];

export const privacidade = [
  {
    type: "Privacidade",
    title:
      "Dos dados pessoais listados abaixo, qual é considerado um dado sensível?",
    options: [
      {
        response: "A) Nome de um HCP",
        correct: false,
      },
      {
        response: "B) Geolocalização de um Kenvuer",
        correct: false,
      },
      {
        response: "C) Perfil de consumo de uma pessoa",
        correct: false,
      },
      {
        response: "D) Dado biométrico",
        correct: true,
      },
    ],
  },

  {
    type: "Privacidade",
    title: "Quem não é considerado um titular de dados?",
    options: [
      {
        response: "A) Kenvuer",
        correct: false,
      },
      {
        response: "B) Consumidor",
        correct: false,
      },
      {
        response: "C) HCP",
        correct: false,
      },
      {
        response: "D) Farmácia",
        correct: true,
      },
    ],
  },

  {
    type: "Privacidade",
    title: "Qual afirmação sobre coleta de dados pessoais é verdadeira?",
    options: [
      {
        response: "A) É permitido coletar dados pessoais sem finalidade.",
        correct: false,
      },
      {
        response:
          "B) É permitido armazenar dados pessoais sem considerar sua utilidade.",
        correct: false,
      },
      {
        response:
          "C) É permitido coletar apenas dados necessários para o propósito especificado.",
        correct: true,
      },
      {
        response:
          "D) É permitido compartilhar dados pessoais com vários parceiros comerciais.",
        correct: false,
      },
    ],
  },
];

export const privacidadeFix = {
  type: "Privacidade",
  title:
    "Privacidade é o direito de ser deixado só e decidir sobre o uso de suas informações pessoais.",
  options: [
    {
      response: "A) Verdadeiro",
      correct: true,
    },
    {
      response: "B) Falso",
      correct: false,
    },
  ],
};

export const rh = [
  {
    type: "RH",
    title:
      "Você presenciou uma situação em que um Kenvuer possivelmente fez algo que pode ser caracterizado como desvio de conduta, violação de política ou alguma outra atitude que não esteja em conformidade com o Kenvue Way, mas você não tem 100% de certeza. O que você deve fazer?",
    options: [
      {
        response:
          "A) Nada, pois não tenho certeza se aquela atitude está alinhada com as normas e políticas da companhia.",
        correct: false,
      },
      {
        response:
          "B) Espero uma oportunidade para encontrar alguém de ER/LR e reportar o caso, mesmo que isto demore alguns dias.",
        correct: false,
      },
      {
        response:
          "C) Procuro a BUHR para compartilhar o caso, porém se não encontrar eu deixo para outro dia.",
        correct: false,
      },
      {
        response:
          "D) Abro um chamado no AskGS ou abro uma denúncia através do Integrity Line, mesmo que eu esteja com dúvida, pois ela será endereçada para o setor responsável.",
        correct: true,
      },
    ],
  },

  {
    type: "RH",
    title:
      "Num determinado PDV (Ponto de Venda), o cliente relata que estava separando algumas amostras grátis para descarte, pois eles estavam com pequenas avarias e me perguntou se eu gostaria de ficar com eles. O que você faria?",
    options: [
      {
        response:
          "A) Aceitaria, pois o meu cliente é de confiança e ele sabe o que faz.",
        correct: false,
      },
      {
        response:
          "B) Explicaria que para estes casos as amostras serão recolhidas, um relatório será preenchido e serão devolvidas para a empresa.",
        correct: true,
      },
      {
        response: "C) Aceitaria e pediria mais amostras grátis.",
        correct: false,
      },
      {
        response:
          "D) Não aceitaria no momento, mas se ele me falasse que não haveria problema, eu aceitaria.",
        correct: false,
      },
    ],
  },

  {
    type: "RH",
    title:
      "Quais os canais de comunicação que a Kenvue disponibiliza para reportar alguma situação que não esteja conforme o Kenvue Way?",
    options: [
      {
        response: "A) Reportar para a chefia.",
        correct: false,
      },
      {
        response: "B) Abrir um case através do AskGS.",
        correct: false,
      },
      {
        response: "C) Abrir uma denúncia através do Kenvue Integrity Line.",
        correct: false,
      },
      {
        response: "D) Todas as alternativas anteriores.",
        correct: true,
      },
    ],
  },

  {
    type: "RH",
    title: "Quando a área de ER/LR deve ser acionada?",
    options: [
      {
        response:
          "A) Quando for observada alguma situação que não esteja conforme com o código de conduta.",
        correct: false,
      },
      {
        response:
          "B) Quando for necessário suporte para iniciar um processo de recuperação de performance de seu funcionario(a).",
        correct: false,
      },
      {
        response:
          "C) Quando houver alguma dúvida com o procedimento, norma ou código de conduta.",
        correct: false,
      },
      {
        response: "D) Todas as alternativas anteriores.",
        correct: true,
      },
    ],
  },

  {
    type: "RH",
    title:
      "Em determinadas situações você poderá ser convidado(a) pela área de ER/LR para uma conversa. Neste caso, você:",
    options: [
      {
        response:
          "A) Deve manter o sigilo e não comentar o assunto com ninguém.",
        correct: true,
      },
      {
        response:
          "B) Devo avisar a minha liderança que participarei de uma entrevista com ER/LR.",
        correct: false,
      },
      {
        response:
          "C) Posso comentar com todos, pois não há restrições para compartilhar o tema/conversa.",
        correct: false,
      },
      {
        response: "D) Nenhuma das anteriores.",
        correct: false,
      },
    ],
  },
];

export const vendas = [
  {
    type: "Farmacovigilância",
    title:
      "Na rotina de trabalho, conversando com amigos e familiares e até mesmo utilizando as redes sociais, um funcionário Kenvue pode ter contato com alguma reclamação ou ocorrência médica relacionada aos produtos Kenuve. Em uma situação como essa, qual é a responsabilidade do funcionário?",
    options: [
      {
        response:
          "A) Consolar a pessoa que está fazendo a reclamação, ou que passou pela ocorrência médica.",
        correct: false,
      },
      {
        response: "B) Defender a empresa e seus produtos.",
        correct: false,
      },
      {
        response:
          "C) Reportar a reclamação ou ocorrência médica ao serviço de atendimento ao consumidor.",
        correct: true,
      },
      {
        response:
          "D) Pedir desculpas em nome da Kenvue e consolar a pessoa que está fazendo a reclamação, ou que passou pela ocorrência médica.",
        correct: false,
      },
    ],
  },

  {
    type: "Farmacovigilância",
    title:
      "Ao entrar em contato com uma reclamação ou evento adverso relacionados aos nossos produtos, é função de todos reportar o ocorrido ao nosso serviço de atendimento ao consumidor (CCC) para dar a devida assistência aos consumidores, promover a melhoria contínua dos produtos e cumprir com nossas obrigações legais. Como e em quanto tempo esse reporte deve ser feito?",
    options: [
      {
        response: "A) Dentro de 24h, via e-mail, formulário ou telefone.",
        correct: true,
      },
      {
        response: "B) Dentro de um dia útil, via email ou telefone.",
        correct: false,
      },
      {
        response: "C) Dentro de uma semana, via formulário ou email.",
        correct: false,
      },
      {
        response: "D) Dentro de 24h, via email.",
        correct: false,
      },
    ],
  },

  {
    type: "Farmacovigilância",
    title:
      "Ao entrar em contato com uma reclamação ou evento adverso relacionados aos nossos produtos, é função de todos reportar o ocorrido ao nosso serviço de atendimento ao consumidor (CCC) para dar a devida assistência aos consumidores, promover a melhoria contínua dos produtos e cumprir com nossas obrigações legais. Quais as informações mínimas que devem ser reportadas?",
    options: [
      {
        response: "A) Evento e Paciente/Consumidor.",
        correct: false,
      },
      {
        response: "B) Produto e Relator.",
        correct: false,
      },
      {
        response: "C) Evento e Relator.",
        correct: false,
      },
      {
        response: "D) Produto e Evento.",
        correct: true,
      },
    ],
  },

  // {
  // type: "Farmacovigilância",
  //   title: (
  //     <div style={{ display: "flex", flexDirection: "column" }}>
  //       <h4>
  //         Estamos em contato com potenciais consumidores o tempo todo! Dessa
  //         forma, um funcionário Kenvue pode ter contato com alguma reclamação ou
  //         ocorrência médica relacionada aos produtos Kenvue, exigindo atenção
  //         constante. Das situações abaixo, quais podem originar reclamações?
  //       </h4>
  //       <h4>Assinale todas que se aplicam.</h4>
  //     </div>
  //   ),
  //   options: [
  //     {
  //       response:
  //         "A) Conversando com médicos e outros profissionais da saúde, que irão descrever o quadro clínico de seus pacientes.",
  //       correct: true,
  //     },
  //     {
  //       response: "B) Navegando pelas redes sociais.",
  //       correct: true,
  //     },
  //     {
  //       response:
  //         "C) Conversando com amigos, familiares ou conhecidos, mesmo que fora do ambiente de trabalho.",
  //       correct: true,
  //     },
  //     {
  //       response: "D) Ao visitar farmácias e mercados.",
  //       correct: true,
  //     },
  //   ],
  // },
];
