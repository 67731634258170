import React, { useState, useContext } from 'react';
import { QuizContext } from '../../../assets/context/index';
import { Card, CardBody } from 'reactstrap';
import './style.css';

// services
import { postSurvey } from '../../../services/survey';
import { createEarnedPoints } from '../../../services/ranking';
// componentes
import Question1 from './question1';
import Question2 from './question2';

import FinalModal from './finalModal';

export default function StefaniniEnquete1Questions(props) {
  const { t } = useContext(QuizContext);
  const { user } = props;
  // todas as questões selecionadas
  const [questionsSelected, setQuestionSelected] = useState({});
  // verificar respostas
  const [checkAnswers, setCheckAnswers] = useState(false);
  // modal de finalização
  const [modal, setModal] = useState(false);
  // erro
  const [erro, setErro] = useState(false);
  // carregando btn
  const [loading, setLoading] = useState(false);

  async function verifyAnswers() {
    setLoading(true);
    // verificar se todas foram respondidas
    if (!questionsSelected.question1 || !questionsSelected.question2) {
      setErro(true);
      setLoading(false);
      return;
    } else setErro(false);

    // enviar dados para api
    if (user.idEvent && user.idPart) {
      const data1 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: questionsSelected.question1.title,
        answer: questionsSelected.question1.option,
        hit: questionsSelected.question1.correct,
      };

      await postSurvey(data1).then((res) => {
        if (res.message === 'created' && questionsSelected.question1.correct) {
          const dataRanking1 = {
            code: 'DATASCIENCEPERGUNTA2',
            positive: true,
            log: 'Acertou enquete',
          };

          createEarnedPoints(dataRanking1, user.idEvent, user.idPart).then(
            (res2) => {
              console.log('2', res2);
            }
          );
        }
      });

      const data2 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: questionsSelected.question2.title,
        answer: questionsSelected.question2.option,
        hit: questionsSelected.question2.correct,
      };

      await postSurvey(data2).then((res) => {
        if (res.message === 'created' && questionsSelected.question2.correct) {
          const dataRanking2 = {
            code: 'EVENTOSPERGUNTA2',
            positive: true,
            log: 'Acertou enquete',
          };

          createEarnedPoints(dataRanking2, user.idEvent, user.idPart);
        }
      });
    }

    setLoading(false);
    setCheckAnswers(true);
    setModal(true);
  }
  return (
    <section className="section-boe">
      {modal && (
        <FinalModal
          isOpen={modal}
          toggle={() => setModal(!modal)}
          questions={questionsSelected}
        />
      )}

      <Card className="card-boe">
        <CardBody>
          <Question1
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />
          <Question2
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />
          {erro && (
            <div style={{ textAlign: 'center' }}>
              <a style={{ color: 'red', fontSize: 17 }}>
                {t('Responda todas perguntas')}
              </a>
            </div>
          )}

          <a
            className={`${checkAnswers ? 'btn-submit-disabled' : 'btn-submit'}`}
            onClick={() => {
              if (!checkAnswers && !loading) {
                verifyAnswers();
              }
            }}
          >
            {t('Salvar respostas')}
          </a>
        </CardBody>
      </Card>
    </section>
  );
}
