import React from "react";
import { Modal, ModalBody } from "reactstrap";

export default function ModalConfirmation(props) {
  const { isOpen } = props;

  return (
    <Modal isOpen={isOpen} centered autoFocus>
      <ModalBody>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3>Enviado</h3>
          <a style={{ fontSize: 15 }}>Sua resposta foi enviada com sucesso!</a>
          <a
            style={{
              width: "100%",
              backgroundColor: "rgb(31, 153, 31)",
              textAlign: "center",
              color: "white",
              padding: 10,
              margin: "10px 0",
              borderRadius: 8
            }}
            onClick={() => window.location.reload(false)}
          >
            OK
          </a>
        </div>
      </ModalBody>
    </Modal>
  );
}
