import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option:
      "a) Um modelo operacional que permite um ganho de maturidade para atingir faturamento flexível que inclui métricas de valor e produtividade.",
    correct: true,
  },
  {
    option: "b) O mesmo que squads produtivas.",
    correct: false,
  },
  {
    option:
      "c) Apenas uma forma de bonificação com o ganho de produtividade com o uso do Agiliza Ai.",
    correct: false,
  },
  {
    option:
      "d) Um sistema de organização baseado em equipes (squads) de alta performance.",
    correct: false,
  },
  {
    option:
      "e) Uma metodologia de trabalho focada em resultados dos diversos setores de uma empresa.",
    correct: false,
  },
];

export default function Question2(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question2: {
          title: "O que é o modelo de Perfomance Squads?",
          option: option.option,
          correct: option.correct,
          order: 2,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t("O que é o modelo de Perfomance Squads?")}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
