import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) Todas estão incluídas na IMPROVE",
    correct: true,
  },
  {
    option: "b) 1 e 2",
    correct: false,
  },
  {
    option: "c) 2 e 3",
    correct: false,
  },
  {
    option: "d) 3",
    correct: false,
  },
  {
    option: "e) 2",
    correct: false,
  },
];

export default function Question32(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question32: {
          title: "A importância da oferta IMPROVE no cenário atual do mercado",
          option: option.option,
          correct: option.correct,
          order: 32
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30, textAlign: "center" }}>
      <h5 style={{ textAlign: "center" }}>
        {t("A importância da oferta IMPROVE no cenário atual do mercado")}
      </h5>

      <p>
        {t(
          "1. Regras de cálculo complexas – IMPROVE simplifica e permite fácil rastreabilidade de regras de cálculo que possuem alta complexidade em sua definição."
        )}
      </p>
      <p>
        {t(
          "2. Restrições às áreas de negócio para incluir alterações ou ajustes nos esquemas de remuneração para se adaptarem aos desafios do mercado – a IMPROVE pode absorver estas mudanças nos esquemas de remuneração muito rapidamente através da parametrização."
        )}
      </p>
      <p>
        {t(
          "3. Acompanhamento do processo comercial e cálculo de remuneração online para todos os interessados ​​– a IMPROVE disponibiliza informações online e apresenta indicadores e cálculos parciais para acompanhamento e controle abrangente do processo."
        )}
      </p>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
