import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import { Card, CardBody } from "reactstrap";
import "./styled.css";

// services
import { postSurvey } from "../../../services/survey";
import { createEarnedPoints } from "../../../services/ranking";
// componentes
import Question1 from "./question1";
import Question2 from "./question2";
import Question3 from "./question3";
import Question4 from "./question4";
import Question5 from "./question5";
import Question6 from "./question6";
import Question7 from "./question7";
import Question8 from "./question8";
import Question9 from "./question9";
import Question10 from "./question10";
import Question11 from "./question11";
import Question12 from "./question12";
import Question13 from "./question13";
import Question14 from "./question14";
import Question15 from "./question15";
import Question16 from "./question16";
import Question17 from "./question17";
import Question18 from "./question18";
import Question19 from "./question19";
import Question20 from "./question20";
import Question21 from "./question21";
import Question22 from "./question22";
import Question23 from "./question23";
import Question24 from "./question24";
import Question25 from "./question25";
import Question26 from "./question26";
import Question27 from "./question27";
import Question28 from "./question28";
import Question29 from "./question29";
import Question30 from "./question30";
import Question31 from "./question31";
import Question32 from "./question32";
import Question33 from "./question33";
import Question34 from "./question34";
import Question35 from "./question35";
import Question36 from "./question36";
import Question37 from "./question37";
import Question38 from "./question38";
import Question39 from "./question39";
import FinalModal from "./finalModal";

export default function StefaniniEnquete1Questions(props) {
  const { t } = useContext(QuizContext);
  const { user } = props;
  // todas as questões selecionadas
  const [questionsSelected, setQuestionSelected] = useState({});
  // verificar respostas certas
  const [checkAnswers, setCheckAnswers] = useState(false);
  // modal de finalização
  const [modal, setModal] = useState(false);
  // erro
  const [erro, setErro] = useState(false);
  // carregando btn
  const [loading, setLoading] = useState(false);
  // quantidade de acertos
  const [correctQuestions, setCorrectQuestions] = useState(0);

  async function verifyAnswers() {
    var answered = 0;
    var key;

    // verificar se todas foram respondidas
    for (key in questionsSelected) {
      if (questionsSelected.hasOwnProperty(key)) {
        answered++;
      }
    }

    setLoading(true);
    if (answered < 39) {
      setErro(true);
      setLoading(false);
      return;
    } else setErro(false);

    // transformar obj em array e ordenar para enviar na ordem para api
    var questionsArray = Object.values(questionsSelected).sort(
      (a, b) => a.order - b.order
    );

    var totalCorrects = 0;

    if (user.idEvent && user.idPart) {
      for (var i = 0; i < questionsArray.length; i++) {
        var question = questionsArray[i];

        if (question.correct) {
          totalCorrects = totalCorrects + 1;
        }

        const data = {
          eventId: user.idEvent,
          loginId: user.idPart,
          question: question.title,
          answer: question.option,
          hit: question.correct,
        };

        await postSurvey(data)
          .then((res) => {
            console.log("ok1 order " + question.order, res);
            if (res.message === "created" && question.correct) {
              const dataRanking = {
                code: "QUIZ2QUESTION" + question.order,
                positive: true,
                log: "Acertou enquete",
              };

              createEarnedPoints(dataRanking, user.idEvent, user.idPart)
                .then((response) => {
                  console.log("ok2 order " + question.order, res);
                })
                .catch((error) =>
                  console.log("error2 order " + question.order, error)
                );
            }
          })
          .catch((error) =>
            console.log("error1 order " + question.order, error)
          );
      }

      setCorrectQuestions(totalCorrects);

      setLoading(false);

      setCheckAnswers(true);

      setModal(true);
    }
  }
  return (
    <section className="section-ste">
      {modal && (
        <FinalModal
          isOpen={modal}
          toggle={() => setModal(!modal)}
          questions={questionsSelected}
          correctQuestions={correctQuestions}
        />
      )}

      <Card className="card-ste">
        <CardBody>
          <h1
            className="title-section-stefanini"
            style={{ marginTop: 0, borderTop: "none" }}
          >
            APP
          </h1>

          <Question1
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question2
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question3
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question4
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question5
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question6
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <h1 className="title-section-stefanini">Marketing</h1>

          <Question7
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question8
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question9
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <h1 className="title-section-stefanini">Cybersecurity</h1>

          <Question10
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question11
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question12
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <h1 className="title-section-stefanini">Manufacturing</h1>

          <Question13
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question14
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question15
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question16
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question17
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question18
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question19
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question20
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question21
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <h1 className="title-section-stefanini">Financial Services</h1>

          <Question22
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question23
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question24
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <h1 className="title-section-stefanini">Analytics</h1>

          <Question25
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question26
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question27
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question28
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question29
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question30
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question31
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question32
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question33
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <h1 className="title-section-stefanini">Enterprise Services</h1>

          <Question34
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question35
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question36
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <h1 className="title-section-stefanini">Digital Workplace</h1>

          <Question37
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question38
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          <Question39
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          {erro && (
            <div style={{ textAlign: "center" }}>
              <a style={{ color: "red", fontSize: 17 }}>
                {t("Responda todas perguntas")}
              </a>
            </div>
          )}

          <a
            className={`${
              checkAnswers || loading ? "btn-submit-disabled" : "btn-submit"
            }`}
            onClick={() => {
              if (!checkAnswers && !loading) {
                verifyAnswers();
              }
            }}
          >
            {loading ? t("Carregando") : t("Salvar respostas")}
          </a>
        </CardBody>
      </Card>
    </section>
  );
}
