import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../../avipam/styled.css";

//component
import BodyCard from "../../../components/bodyCard";

const alternatives = [
  { name: "AVIPAM UP", isCorrect: true },
  { name: "AVIPAMBEN", isCorrect: false },
  { name: "AVIPAM CULTURAL", isCorrect: false },
  { name: "AVIPAMFLIX", isCorrect: false },
]

export default function AvipamEnquete3() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");
      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);
  return (
    <div className="section-files">
      <div className="div-files">
        <BodyCard
          user={user}
          type={"Enquete 3"}
          fileName="Enquete3"
          title={"Como chama o programa que lançamos em 2022 de Plano de Carreira para desenvolvimento de nossos colaboradores?"}
          alternatives={alternatives}
          description={[{ data: "" }]}
          id={"d2bbebe2-28ee-451a-ae00-c56b6ca993f9"}
          button={"Enviar"}
          codeRanking="ENQUETE3-EXTERNO"
        />
      </div>
    </div>
  );
}
