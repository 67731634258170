import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// componentes
import PageQuestions from "./pageQuestions";
import PageAnswered from "./pageAnswered";
// service
import { verifySurveyByUser } from "../../services/survey";

export default function Kenvue() {
  // parametros
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState(null);
  // step
  const [step, setStep] = useState(0);

  function verifyUser() {
    verifySurveyByUser({
      eventId: user.idEvent,
      loginId: user.idPart,
      question:
        "Privacidade é o direito de ser deixado só e decidir sobre o uso de suas informações pessoais.",
    }).then((res) => {
      if (res.message === "QUESTION_ANSWERED") {
        setStep(1);
      } else if (res.message === "QUESTION_AVAILABLE") {
        setStep(2);
      } else {
        setStep(0);
        alert("Erro ao verificar usuário");
      }
    });
    // .catch((error) => console.log("part erro", error));
  }

  useEffect(() => {
    if (user) {
      verifyUser();
    }
  }, [user]);

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);

  return (
    <>
      <section className="section-kenvue">
        {step === 1 && <PageAnswered />}
        {step === 2 && <PageQuestions user={user} />}
      </section>
    </>
  );
}
