import axios from "axios";

// const urlBase = `${process.env.REACT_APP_URL_BASE}`;
const urlBase = `https://gateway-admin-plus.sistemasinteegra.com.br`;

// tempo que levou a ser respondido
function timeByUser(data) {
  var config = {
    method: "post",
    url: `${urlBase}/api-admin-mobile/temp/game/answertime`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getTimeUser(idEvent) {
  var config = {
    method: "get",
    url: `${urlBase}/api-admin-mobile/temp/game/rankinganswertime/${idEvent}/event?offset=1&limit=10000`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { timeByUser, getTimeUser };
