import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress } from "reactstrap";
import Trofeu from "../../assets/images/neogen/trofeu.jpeg";
import { getTotalPoints } from "../../services/ranking";
import { getTimeUser } from "../../services/userTime";

export default function Finish(props) {
  const {
    user,
    materialSelecteds,
    ambientalSelecteds,
    controlSelecteds,
    materialCorrect,
    ambientalCorrect,
    controlCorrect,
    productSelected,
    cancelGame,
  } = props;
  const [points, setPoints] = useState(null);
  const [pointsProgress, setPointsProgress] = useState(0);
  const [time, setTime] = useState(null);
  const [allData, setAllData] = useState([]);

  // remover obj duplicados
  function formatArray(array) {
    var values = array.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));

    return values;
  }

  async function verifyData(selecteds, corrects, title) {
    var allCorrects = [];
    var allIncorrects = [];

    selecteds.map((selected) => {
      var search = corrects.filter((e) => e.title === selected);

      if (search[0]) {
        allCorrects.push(selected);
      } else allIncorrects.push(selected);
    });

    var data = {
      title: title,
      corrects: allCorrects,
      incorrects: allIncorrects,
    };

    var arrayAllData = allData;

    arrayAllData.push(data);

    setAllData(arrayAllData);

    return true;
  }

  async function getData() {
    if (materialSelecteds.length > 0) {
      await verifyData(
        materialSelecteds,
        materialCorrect,
        "Recepção de materias primas"
      );
    }

    if (ambientalSelecteds.length > 0) {
      await verifyData(
        ambientalSelecteds,
        ambientalCorrect,
        "Monitoramento Ambiental"
      );
    }

    if (controlSelecteds.length > 0) {
      await verifyData(
        controlSelecteds,
        controlCorrect,
        "Controle de qualidade do produto acabado"
      );
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (user) {
      getTotalPoints(user.idEvent, user.idPart).then((res) => {
        if (res.totalPoints) setPoints(res.totalPoints);
      });
      // .catch((error) => console.log("erro", error));

      getTimeUser(user.idEvent).then((res) => {
        if (
          res?.data?.filter((e) => e.login_id === user.idPart)[0]?.answered_time
        ) {
          setTime(
            res.data.filter((e) => e.login_id === user.idPart)[0].answered_time
          );
        }
      });
      // .catch((error) => console.log("erro time", error));
    }
  }, [user]);

  useEffect(() => {
    if (points) {
      var progress = (points * 100) / 480;

      setPointsProgress(progress);
    }
  }, [points]);

  return (
    <>
      <div>
        <a className="text-thanks">Muito obrigado pela ajuda!!</a>

        <div className="div-img-finish">
          <img className="img-trofeu-finish" src={Trofeu} />
          {points && (
            <h3 className="text-finish">
              Parabéns! Você atingiu {points} pontos {time && <a>em {time}</a>}!
            </h3>
          )}
        </div>

        {points && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a style={{ margin: "0 10px" }}>0</a>
              <Progress
                striped
                animated
                color="success"
                value={pointsProgress}
                style={{ width: "100%" }}
              />
              <a style={{ margin: "0 10px" }}>480</a>
            </div>
            {points < 350 && (
              <div style={{ textAlign: "center", margin: "20px 0" }}>
                <a>
                  Que pena, você não atingiu a pontuação mínima para concorrer
                  aos prêmios.
                </a>
              </div>
            )}
          </>
        )}

        {allData.length > 0 && (
          <>
            <h2 className="title-product-selected">
              Produto escolhido: {productSelected.toUpperCase()}
            </h2>
            <div className="finish-options">
              {formatArray(allData).map((data, i) => (
                <div
                  key={`id${data.title}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{
                      marginTop: 20,
                      textAlign: "center",
                    }}
                  >
                    {data.title}
                  </h3>
                  {data.corrects.map((correct, indexCorrect) => (
                    <div
                      className="finish-card-option"
                      style={{ backgroundColor: "rgb(82, 214, 82)" }}
                      key={data.title + correct}
                    >
                      <a>{correct}</a>
                    </div>
                  ))}

                  {data.incorrects.map((incorrect, indexIncorrect) => (
                    <div
                      className="finish-card-option"
                      style={{ backgroundColor: "rgb(228, 51, 51)" }}
                      key={data.title + incorrect}
                    >
                      <a>{incorrect}</a>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}

        {cancelGame && (
          <Row>
            <Col md={12}>
              <div
                className="div-btn-submit"
                style={{ justifyContent: "center" }}
              >
                <Button
                  className="btn-warning"
                  onClick={() => window.location.reload()}
                >
                  Voltar ao início
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
