import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./styled.css";

const questions = [
  {
    option: "a) Foco exclusivo em insights analíticos.",
    correct: false,
  },
  {
    option: "b) Melhoria na produtividade e na eficiência de processos.",
    correct: true,
  },
  {
    option: "c) Implementação de novos recursos tecnológicos.",
    correct: false,
  },
  {
    option:
      "d) Potencialização de tomadas de decisões estratégicas através da inteligência de dados.",
    correct: false,
  },
  {
    option: "e) Otimização de custos operacionais",
    correct: false,
  },
];

export default function Question6(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question6: {
          title:
            "Quais são os principais benefícios da solução Agiliza.Ai em Tecnology Consultancy And Transformation?",
          option: option.option,
          correct: option.correct,
          order: 6
        },
      };
    });
  }
  return (
    <div className="div-question">
      <h5 style={{ textAlign: "center" }}>
        {t(
          "Quais são os principais benefícios da solução Agiliza.Ai em Tecnology Consultancy And Transformation?"
        )}
      </h5>

      <div className="div-options-question-ste">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-ste ${
              optionSelected === e.option && "selected-option-question-ste"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
