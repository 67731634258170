import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
// service
import { postSurvey } from "../../../services/survey";
import { createEarnedPoints } from "../../../services/ranking";
//alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function Step3(props) {
  const [successAlert, setSuccessAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [loadinBtn, setBtnLoading] = useState(false);

  function submitForm(e) {
    setBtnLoading(true);

    if (!e.target[0].value) {
      setAlert("A pergunta é obrigatória");
      return;
    }

    sendToApi(e);
  }

  async function sendToApi(e) {
    // primeira resposta
    await postSurvey(props.answer[0])
      .then((res) => {
        // console.log("ok", res)
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // segunda resposta
    await postSurvey(props.answer[1])
      .then((res) => {
        // console.log("ok", res)
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // terceira resposta
    const question3 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question:
        "Por qual(is) motivo(s) nunca utilizou aplicativos para compras de Mercado?",
      answer: e.target[0].value,
      hit: true,
      // questionOrder: 3,
    };
    await postSurvey(question3)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // pontuação
    const dataRanking = {
      code: "DESAFIO4",
      positive: true,
      log: "Acertou enquete",
    };
    await createEarnedPoints(
      dataRanking,
      props.user.idEvent,
      props.user.idPart
    );

    setSuccessAlert(true);
  }

  // se ja foi respondido (retornar a essa pergunta), é descartado para uma nova resposta
  useEffect(() => {
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 3));
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitForm(e);
      }}
    >
      {successAlert ? (
        <SweetAlert
          success
          title={"Enviado"}
          onConfirm={() => {
            setBtnLoading(false);
            window.location.reload();
          }}
        >
          Formulário enviado com sucesso
        </SweetAlert>
      ) : null}

      {alert ? (
        <SweetAlert
          warning
          title={"Campo(s) vazio(s)"}
          onConfirm={() => {
            setBtnLoading(false);
            setAlert("");
          }}
        >
          {alert}
        </SweetAlert>
      ) : null}

      {errorAlert ? (
        <SweetAlert
          warning
          title={"Alerta!"}
          onConfirm={() => {
            setBtnLoading(false);
            setErrorAlert("");
          }}
        >
          {errorAlert}
        </SweetAlert>
      ) : null}

      <label className="form-label">
        3. Por qual(is) motivo(s) nunca utilizou aplicativos para compras de
        Mercado?
      </label>
      <input className="form-control" type="text" placeholder="Sua resposta" />

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="btn-back" onClick={() => props.setStep("b")}>
              Voltar
            </button>

            <button
              className="btn-submit"
              type="submit"
              disabled={loadinBtn}
              style={{
                backgroundColor: loadinBtn && "gray",
              }}
            >
              {loadinBtn ? "Carregando" : "Enviar"}
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
}
