import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./style.css";

const questions = [
  {
    option:
      "a)  O target de premiação (prêmio 100%) corresponde a 30% do salário bruto.",
    correct: false,
  },
  {
    option:
      "b) Quando um consultor(a) é transferido de território é garantido por 2 (dois) meses o prêmio target 100% ou a média dos últimos seis meses (o que for maior).",
    correct: false,
  },
  {
    option:
      "c) Sempre que ocorre uma alteração na política de premiação, o colaborador deve se atualizar sobre as mudanças e registrar seu ‘de acordo’ através do Portal de Premiação (IQVIA).",
    correct: false,
  },
  {
    option:
      "d) Para acessar o Portal de Premiação (IQVIA) não é necessário colocar login e senha, basta estar conectado ao Global Protect que o acesso é automático.",
    correct: false,
  },
  {
    option: "e) Todas as alternativas anteriores",
    correct: true,
  },
];

export default function Question1(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question1: {
          title: "Sobre Remuneração Variável é correto afirmar que:",
          option: option.option,
          correct: option.correct,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        {t("Sobre Remuneração Variável é correto afirmar que:")}
      </h5>

      <div className="div-options-question-boe">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-boe ${
              optionSelected === e.option && "selected-option-question-boe"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
