import React from "react";
import { Row, Col, Button } from "reactstrap";

export default function Information(props) {
  const { setStep, cancelGame } = props;
  return (
    <>
      <p>
        <h3>
          Estamos muito felizes em ter sua ajuda para definir o controle de
          qualidade de nosso laticínio!
        </h3>
      </p>

      <p>
        <h3>Como funcionará este jogo?</h3>
      </p>

      <p className="p-about-neogen">
        <a>
          1. Você irá escolher um produto que será trabalhado durante todo o
          jogo.
        </a>

        <a>
          2. Para cada etapa do processo de produção você irá relacionar as
          melhores soluções Neogen.{" "}
        </a>
        <a>
          3. O objetivo é relacionar os produtos Neogen corretos no menor tempo
          possível.
        </a>
      </p>

      <p>
        <h3>Está preparado? Vamos começar?</h3>
      </p>

      <Row>
        <Col md={12}>
          <div className="div-btn-submit">
            {cancelGame && (
              <Button
                className="btn-danger"
                style={{ marginRight: 20 }}
                onClick={() => window.location.reload()}
              >
                Voltar ao início
              </Button>
            )}
            <Button
              className="btn-success"
              onClick={() => {
                setStep(2);
              }}
            >
              Próximo
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
