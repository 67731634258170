import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import "./styled.css";
// services
import { postSurvey } from "../../services/survey";
// componentes
import ModalConfirmation from "./modalConfirmation";

const options = [
  "CATARATAS BRASIL",
  "ITAIPU VISITA ESPECIAL",
  "NÃO TENHO INTERESSE NOS PASSEIOS",
];

export default function Question(props) {
  const { user } = props;
  const [selected, setSelected] = useState("");
  const [erro, setErro] = useState(false);
  const [modal, setModal] = useState(false);

  async function submitForm() {
    if (!selected) {
      setErro(true);
      return;
    } else setErro(false);

    const data = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "Escolha seu passeio",
      answer: selected,
      hit: true,
    };

    await postSurvey(data).then((res) => {
      if (res.message === "created") {
        setModal(true);
      }
    });
  }

  return (
    <div className="div-boehringer">
      {modal && <ModalConfirmation isOpen={modal} />}
      <Card className="card-boehringer">
        <CardBody>
          <h3>Manhã do dia 17 de Janeiro</h3>

          <p>CATARATAS BRASIL</p>

          <p>
            Localizada dentro do Parque Nacional, o passeio consiste em uma
            caminhada de 01 Km. Durante esta caminhada há vistas panorâmicas das
            Cataratas e, ao final, uma fantástica aproximação (molhada) da
            Garganta do Diabo e principais saltos. O passeio termina na parte
            superior das Cataratas do Iguaçu, cujo acesso se dá através de
            escadas ou elevador panorâmico.
          </p>

          <p>ITAIPU VISITA ESPECIAL</p>

          <p>
            Permite a visita ao interior da barragem. Dispõe de atendimento
            diferenciado, utilização de sala especial para a exibição de um
            filme sobre Itaipu e ônibus especial e roteiro para acompanhamento.
            O roteiro tem sete etapas (em todas elas é permitido fotografar e
            filmar): Estação Mirante Central; Barragem de Concreto; Catedral;
            Edifício da Produção; Sala de Comando
          </p>

          <p>
            *Não é permitido o uso de shorts, saias, chinelos, sandálias ou
            sapatos de salto durante a visita.
          </p>
          <h3 style={{ textAlign: "center", marginBottom: 10 }}>
            Escolha seu passeio
          </h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {options.map((e, i) => (
              <a
                className={`option-boehringer ${
                  selected === e && "option-boehringer-selected"
                }`}
                onClick={() => setSelected(e)}
              >
                {e}
              </a>
            ))}

            {erro && (
              <a style={{ color: "red", textAlign: "center" }}>
                Selecione uma opção
              </a>
            )}

            <a className="btn-submit-boehringer" onClick={() => submitForm()}>
              Salvar
            </a>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
