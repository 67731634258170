import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./styled.css";
//alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { postSurvey } from "../../../services/survey";
import { createEarnedPoints } from "../../../services/ranking";

export default function Step5(props) {
  // alerta
  const [successAlert, setSuccessAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  // campos do form
  const [otherIpunt, setOtherInput] = useState(false);
  const [otherIpuntValue, setOtherInputValue] = useState("");
  const [criteria, setCriteria] = useState("");
  const [buyIfood, setBuyIfood] = useState("");
  const [avaliation, setAvaliation] = useState("");
  const [bestXpInput, setBestXpInput] = useState("");
  const [loadinBtn, setBtnLoading] = useState(false);
  // estrela
  const [colorStar, setColorStar] = useState("");

  function submitForm() {
    setBtnLoading(true);

    if (!criteria && !otherIpunt) {
      setAlert(
        'O campo "Qual dos critérios abaixo você utilizou para escolher o Mercado na suas últimas compras aqui no iFood?" é obrigatório'
      );
      return;
    }

    if (otherIpunt && !otherIpuntValue) {
      setAlert('O campo "Outro (especifique)" é obrigatório');
      return;
    }

    if (!buyIfood) {
      setAlert(
        'O campo "No último mês, quantas vezes você fez compra de Mercado no iFood?" é obrigatório'
      );
      return;
    }

    if (!avaliation) {
      setAlert(
        'O campo "Como você avalia sua experiência comprando Mercado no iFood?" é obrigatório'
      );
      return;
    }

    if (!bestXpInput) {
      setAlert(
        'O campo "O que você diria que poderia ter sido melhor na sua experiência?" é obrigatório'
      );
      return;
    }

    sendToApi();
  }

  async function sendToApi() {
    // primeira resposta
    await postSurvey(props.answer[0])
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // segunda resposta
    const question2 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question:
        "Qual dos critérios abaixo você utilizou para escolher o Mercado na suas últimas compras aqui no iFood?",
      answer: otherIpunt ? criteria + " " + otherIpuntValue : criteria,
      hit: true,
      // questionOrder: 2,
    };
    await postSurvey(question2)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // terceira resposta
    const question3 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question:
        "No último mês, quantas vezes você fez compra de Mercado no iFood?",
      answer: buyIfood,
      hit: true,
      // questionOrder: 3,
    };
    await postSurvey(question3)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // quarta resposta
    const question4 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question: "Como você avalia sua experiência comprando Mercado no iFood?",
      answer: avaliation,
      hit: true,
      // questionOrder: 4,
    };
    await postSurvey(question4)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // quinta resposta
    const question5 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question:
        "O que você diria que poderia ter sido melhor na sua experiência?",
      answer: bestXpInput,
      hit: true,
      // questionOrder: 5,
    };
    await postSurvey(question5)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // pontuação
    const dataRanking = {
      code: "DESAFIO4",
      positive: true,
      log: "Acertou enquete",
    };
    await createEarnedPoints(
      dataRanking,
      props.user.idEvent,
      props.user.idPart
    );

    setSuccessAlert(true);
  }

  function addOptionsCheck(option) {
    if (criteria) {
      setCriteria(criteria + `${option}, `);
    } else setCriteria(`${option}, `);
  }

  function removeOptionCheck(option) {
    setCriteria(criteria.replace(`${option}, `, ""));
  }

  useEffect(() => {
    if (avaliation === "Muito ruim") {
      setColorStar("#b10114");
    } else if (avaliation === "Ruim") {
      setColorStar("#ff0136");
    } else if (avaliation === "Neutro") {
      setColorStar("#f9ab00");
    } else if (avaliation === "Bom") {
      setColorStar("#01e161");
    } else if (avaliation === "Muito bom") {
      setColorStar("#03b940");
    } else setColorStar("");
  }, [avaliation]);

  useEffect(() => {
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 2));
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 3));
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 4));
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 5));
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitForm();
      }}
    >
      {successAlert ? (
        <SweetAlert
          success
          title={"Enviado"}
          onConfirm={() => {
            setBtnLoading(false);
            window.location.reload();
          }}
        >
          Formulário enviado com sucesso
        </SweetAlert>
      ) : null}

      {alert ? (
        <SweetAlert
          warning
          title={"Campo(s) vazio(s)"}
          onConfirm={() => {
            setBtnLoading(false);
            setAlert("");
          }}
        >
          {alert}
        </SweetAlert>
      ) : null}

      {errorAlert ? (
        <SweetAlert
          warning
          title={"Alerta!"}
          onConfirm={() => {
            setBtnLoading(false);
            setErrorAlert("");
          }}
        >
          {errorAlert}
        </SweetAlert>
      ) : null}

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <label className="form-label">
            2. Qual dos critérios abaixo você utilizou para escolher o Mercado
            na suas últimas compras aqui no iFood?
          </label>
          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check1"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck(
                        "Disponibilidade os itens que eu estava buscando"
                      );
                    } else
                      removeOptionCheck(
                        "Disponibilidade os itens que eu estava buscando"
                      );
                  }}
                />
                <label className="form-check-label" htmlFor="check1">
                  Disponibilidade os itens que eu estava buscando
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check2"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Taxa de entrega");
                    } else removeOptionCheck("Taxa de entrega");
                  }}
                />
                <label className="form-check-label" htmlFor="check2">
                  Taxa de entrega
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check3"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Ofertas e Promoções");
                    } else removeOptionCheck("Ofertas e Promoções");
                  }}
                />
                <label className="form-check-label" htmlFor="check3">
                  Ofertas e Promoções
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check4"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Cupom disponível");
                    } else removeOptionCheck("Cupom disponível");
                  }}
                />
                <label className="form-check-label" htmlFor="check4">
                  Cupom disponível
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check5"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Preço dos itens");
                    } else removeOptionCheck("Preço dos itens");
                  }}
                />
                <label className="form-check-label" htmlFor="check5">
                  Preço dos itens
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check6"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Mercado conhecido");
                    } else removeOptionCheck("Mercado conhecido");
                  }}
                />
                <label className="form-check-label" htmlFor="check6">
                  Mercado conhecido
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check7"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Tempo de entrega");
                    } else removeOptionCheck("Tempo de entrega");
                  }}
                />
                <label className="form-check-label" htmlFor="check7">
                  Tempo de entrega
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check8"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Método de pagamento");
                    } else removeOptionCheck("Método de pagamento");
                  }}
                />
                <label className="form-check-label" htmlFor="check8">
                  Método de pagamento
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="check9"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => setOtherInput(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="check9">
                  Outro
                </label>
              </div>
              {otherIpunt && (
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Sua resposta"
                  onChange={(e) => setOtherInputValue(e.target.value)}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <label className="form-label" onClick={() => console.log(buyIfood)}>
            3. No último mês, quantas vezes você fez compra de Mercado no iFood?
          </label>
          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="checkbuy"
                  id="option1"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={() => setBuyIfood("1 vez")}
                />
                <label className="form-check-label" htmlFor="option1">
                  1 vez
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="checkbuy"
                  id="option2"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={() => setBuyIfood("2 vezes")}
                />
                <label className="form-check-label" htmlFor="option2">
                  2 vezes
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="checkbuy"
                  id="option3"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={() => setBuyIfood("3 vezes")}
                />
                <label className="form-check-label" htmlFor="option3">
                  3 vezes
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="checkbuy"
                  id="option4"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={() => setBuyIfood("4 vezes")}
                />
                <label className="form-check-label" htmlFor="option4">
                  4 vezes
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="checkbuy"
                  id="option5"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={() => setBuyIfood("5 vezes ou mais")}
                />
                <label className="form-check-label" htmlFor="option5">
                  5 vezes ou mais
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="checkbuy"
                  id="option6"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={() => setBuyIfood("Nenhuma das anteriores")}
                />
                <label className="form-check-label" htmlFor="option6">
                  Nenhuma das anteriores
                </label>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <label className="form-label">
            4. Como você avalia sua experiência comprando Mercado no iFood?
          </label>
          <div className="div-avaliation mb-3">
            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Muito ruim")}
              style={{
                backgroundColor: avaliation === "Muito ruim" && "#b10114",
                color: avaliation === "Muito ruim" && "white",
              }}
            >
              <a>Muito ruim</a>
              <i
                className="mdi mdi-star"
                style={{
                  fontSize: 20,
                  color:
                    avaliation === "Ruim" ||
                    avaliation === "Neutro" ||
                    avaliation === "Bom" ||
                    avaliation === "Muito bom"
                      ? colorStar
                      : "",
                }}
              />
            </div>

            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Ruim")}
              style={{
                backgroundColor: avaliation === "Ruim" && "#ff0136",
                color: avaliation === "Ruim" && "white",
              }}
            >
              <a>Ruim</a>
              <i
                className="mdi mdi-star"
                style={{
                  fontSize: 20,
                  color:
                    avaliation === "Neutro" ||
                    avaliation === "Bom" ||
                    avaliation === "Muito bom"
                      ? colorStar
                      : "",
                }}
              />
            </div>

            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Neutro")}
              style={{
                backgroundColor: avaliation === "Neutro" && "#f9ab00",
                color: avaliation === "Neutro" && "white",
              }}
            >
              <a>Neutro</a>
              <i
                className="mdi mdi-star"
                style={{
                  fontSize: 20,
                  color:
                    avaliation === "Bom" || avaliation === "Muito bom"
                      ? colorStar
                      : "",
                }}
              />
            </div>

            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Bom")}
              style={{
                backgroundColor: avaliation === "Bom" && "#01e161",
                color: avaliation === "Bom" && "white",
              }}
            >
              <a>Bom</a>
              <i
                className="mdi mdi-star"
                style={{
                  fontSize: 20,
                  color: avaliation === "Muito bom" ? colorStar : "",
                }}
              />
            </div>

            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Muito bom")}
              style={{
                backgroundColor: avaliation === "Muito bom" && "#03b940",
                color: avaliation === "Muito bom" && "white",
              }}
            >
              <a>Muito bom</a>
              <i className="mdi mdi-star" style={{ fontSize: 20 }} />
            </div>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <label className="form-label">
            5. O que você diria que poderia ter sido melhor na sua experiência?
          </label>
          <textarea
            className="form-control"
            type="text"
            placeholder="Sua resposta"
            onChange={(e) => setBestXpInput(e.target.value)}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="btn-back" onClick={() => props.setStep("a")}>
              Voltar
            </button>

            <button
              className="btn-submit"
              type="submit"
              disabled={loadinBtn}
              style={{
                backgroundColor: loadinBtn && "gray",
              }}
            >
              {loadinBtn ? "Carregando" : "Enviar"}
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
}
