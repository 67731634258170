import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import "./style.css";

const questions = [
  {
    option:
      "a) Aqueles que trabalham em conjunto somente as áreas de Treinamento e Eventos.",
    correct: false,
  },
  {
    option:
      "b) Projetos planejados visando potencializar uma entrega de dados mais integralizada, com o envolvimento das áreas de CommOps, construindo uma jornada com os times de mkt e área médica, alinhando os fatores de sucesso do evento e a relevância do conteúdo e o público target do evento, para posterior apresentação de resultados e atingimento de objetivos;",
    correct: true,
  },
  {
    option: "c) Apenas pela experiência 360° proporcionada aos convidados.",
    correct: false,
  },
  {
    option: "d) Todas as alternativas acima.",
    correct: false,
  },
];

export default function Question2(props) {
  const { t } = useContext(QuizContext);
  const { setQuestionSelected, checkAnswers } = props;
  const [optionSelected, setOptionSelected] = useState("");

  function selectOption(option) {
    setOptionSelected(option.option);

    setQuestionSelected((state) => {
      return {
        ...state,
        question2: {
          title: "Os Projetos de Eventos 360° são_____________",
          option: option.option,
          correct: option.correct,
        },
      };
    });
  }
  return (
    <div style={{ marginTop: 30 }}>
      <h5 style={{ textAlign: "center" }}>
        Complete a frase:
        <p style={{ marginTop: "10px" }}>
          Os Projetos de Eventos 360° são
          <span
            style={{
              color: "transparent",
              borderBottom: "1px solid black",
            }}
          >
            _____________
          </span>
        </p>
      </h5>

      <div className="div-options-question-boe">
        {questions.map((e, i) => (
          <a
            key={i}
            className={`option-question-boe ${
              optionSelected === e.option && "selected-option-question-boe"
            } ${checkAnswers && (e.correct ? "check-true" : "check-false")}`}
            onClick={() => {
              if (!checkAnswers) {
                selectOption(e);
              }
            }}
          >
            {t(e.option)}
          </a>
        ))}
      </div>
    </div>
  );
}
