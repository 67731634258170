import React, { useEffect, useState } from "react";
import "./styled.css";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// questões
import {
  etica,
  techData,
  privacidade,
  privacidadeFix,
  rh,
  vendas,
} from "./questions";
// componentes
import OptionQuestion from "./optionQuestion";
// service
import { postSurvey } from "../../services/survey";
import { createEarnedPoints } from "../../services/ranking";

export default function PageQuestions(props) {
  const { user } = props;
  // perguntas
  const [answered, setAnswered] = useState([]);
  const [eticaQuestion, setEticaQuestion] = useState([]);
  const [techDataQuestion, setTechDataQuestion] = useState([]);
  const [privacidadeQuestion, setPrivacidadeQuestion] = useState([]);
  const [rhQuestion, setRhQuestion] = useState([]);
  const [vendasQuestion, setVendasQuestion] = useState([]);
  // alertas
  const [alertWarning, setAlertWarning] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  // botão
  const [loading, setLoading] = useState(false);
  //   mostrar certas
  const [showCorrects, setShowCorrects] = useState(false);

  function getQuestions() {
    shuffle(etica);
    shuffle(techData);
    shuffle(privacidade);
    shuffle(rh);
    shuffle(vendas);

    setEticaQuestion(etica.slice(0, 3));
    setTechDataQuestion(techData.slice(0, 2));
    setPrivacidadeQuestion([privacidade[0], privacidadeFix]);
    setRhQuestion(rh.slice(0, 3));
    setVendasQuestion(vendas.slice(0, 2));
  }

  // embaralhar array
  function shuffle(o) {
    for (
      var j, x, i = o.length;
      i;
      j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
    );
    return o;
  }

  async function submitQuestions() {
    setLoading(true);

    if (answered.length < 12) {
      setAlertWarning(true);
      setLoading(false);
      return;
    }

    for (var i = 0; i < answered.length; i++) {
      const data = answered[i];

      await sendToApi({
        question: data.title,
        answer: data.response,
        hit: data.correct,
        code: data.code,
      });
    }

    setShowCorrects(true);
    setAlertSuccess(true);
  }

  async function sendToApi({ question, answer, hit, code }) {
    const data = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: question,
      answer: answer,
      hit: hit,
    };
    await postSurvey(data);
    // .then((res) => console.log(`api - ${question}`, res));

    if (hit) {
      await sendToRanking(code);
    }
  }

  async function sendToRanking(code) {
    const dataRanking = {
      code: code,
      positive: true,
      log: "Acertou enquete",
    };
    await createEarnedPoints(dataRanking, user.idEvent, user.idPart);
    // .then(
    //   (res) => console.log("api pontos", res)
    // );
  }

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <>
      {alertWarning && (
        <SweetAlert
          warning
          title="Atenção"
          onConfirm={() => {
            setAlertWarning(false);
          }}
        >
          Responda todas as perguntas
        </SweetAlert>
      )}

      {alertSuccess && (
        <SweetAlert
          success
          title="Enviado"
          onConfirm={() => {
            setAlertSuccess(false);
          }}
        >
          Responda enviadas com sucesso
        </SweetAlert>
      )}

      <div className="div-data-kenvue">
        {eticaQuestion.map((e, i) => (
          <div key={e.title}>
            <OptionQuestion
              e={e}
              answered={answered}
              setAnswered={setAnswered}
              code={`ETICA&COMPLIANCE${i + 1}`}
              showCorrects={showCorrects}
            />
          </div>
        ))}
        {techDataQuestion.map((e, i) => (
          <div key={e.title}>
            <OptionQuestion
              e={e}
              answered={answered}
              setAnswered={setAnswered}
              code={`TECH&DATA${i + 1}`}
              showCorrects={showCorrects}
            />
          </div>
        ))}
        {privacidadeQuestion.map((e, i) => (
          <div key={e.title}>
            <OptionQuestion
              e={e}
              answered={answered}
              setAnswered={setAnswered}
              code={`PRIVACIDADE${i + 1}`}
              showCorrects={showCorrects}
            />
          </div>
        ))}
        {rhQuestion.map((e, i) => (
          <div key={e.title}>
            <OptionQuestion
              e={e}
              answered={answered}
              setAnswered={setAnswered}
              code={`RH${i + 1}`}
              showCorrects={showCorrects}
            />
          </div>
        ))}
        {vendasQuestion.map((e, i) => (
          <div key={e.title}>
            <OptionQuestion
              e={e}
              answered={answered}
              setAnswered={setAnswered}
              code={`FARMACOVIGILANCIA${i + 1}`}
              showCorrects={showCorrects}
            />
          </div>
        ))}

        <a
          className="btn-submit"
          style={{
            margin: "20px 0",
            borderRadius: 0,
            backgroundColor: loading ? "gray" : "rgb(46, 150, 46)",
          }}
          onClick={() => {
            if (!loading) {
              submitQuestions();
            }
          }}
        >
          {loading ? "Carregando" : "Enviar"}
        </a>
      </div>
    </>
  );
}
