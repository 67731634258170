import React, { useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import { Card, CardBody } from "reactstrap";

export default function CardQuestionAnswered() {
  const { t } = useContext(QuizContext);
  return (
    <section
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#e2fc36",
      }}
    >
      <Card style={{ width: "90%" }}>
        <CardBody>
          <div style={{ textAlign: "center" }}>
            <h2>{t("Parabéns!")}</h2>
            <a>{t("Você já respondeu o Quiz")}</a>
            <i
              className="mdi mdi-comment-check-outline"
              style={{ color: "green", fontSize: 20 }}
            />
          </div>
        </CardBody>
      </Card>
    </section>
  );
}
