import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../../avipam/styled.css";

//component
import BodyCard from "../../../components/bodyCard";

const alternatives = [
  { name: "2020", isCorrect: false },
  { name: "2019", isCorrect: true },
  { name: "2017", isCorrect: false },
  { name: "2013", isCorrect: false },
];

export default function AvipamEnquete1() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      // var user = window.location.search.split("?");
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");
      // const native = searchParams.get("native");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
        // native: native,
      });
    }
  }, []);
  return (
    <div className="section-files">
      <div className="div-files">
        <BodyCard
          user={user}
          type={"Enquete 1"}
          fileName="Enquete"
          title={"Em que ano a AVIPAM lançou a parceria com a Travel Leaders?"}
          alternatives={alternatives}
          description={[{ data: "" }]}
          id={"e15ffb17-a411-4490-9a98-a6a45b6ec31c"}
          button={"Enviar"}
          codeRanking="ENQUETE1-EXTERNO"
        />
      </div>
    </div>
  );
}
