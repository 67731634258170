import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../../avipam/styled.css";

//component
import BodyCard from "../../../components/bodyCard";

const alternatives = [
  { name: "Influenciar uma geração a ressignificar sua identidade e ser potencializadora do seu futuro, causando impacto em sua comunidade local", isCorrect: false },
  { name: "Transformar cada viagem ou evento em uma experiência encantadora", isCorrect: true },
  { name: "Atender o cliente com qualidade e inovação", isCorrect: false },
  { name: "Conectamos pessoas e empresas através de acessibilidade digital", isCorrect: false },
]

export default function AvipamEnquete2() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);

  return (
    <div className="section-files">
      <div className="div-files">
        <BodyCard
          user={user}
          type={"Enquete 2"}
          fileName="Enquete2"
          title={"Qual é o nosso propósito?"}
          alternatives={alternatives}
          description={[{ data: "" }]}
          id={"d33c7001-6fa9-48a6-89a3-5b31bc317a9b"}
          button={"Enviar"}
          codeRanking="ENQUETE2-EXTERNO"
        />
      </div>
    </div>
  );
}
