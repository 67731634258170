import React, { useState, useEffect } from "react";
import { Input } from 'reactstrap'
import { postSurvey } from "../services/survey";
import { createEarnedPoints } from '../services/ranking';
import SweetAlert from "react-bootstrap-sweetalert";
import "./styled.css";

export default function BodyCard(props) {
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(true);
  const [surveyAnswered, setSurveyAnswered] = useState(false)
  const [surveySuccess, setSurveySuccess] = useState(false)
  const [surveyExists, setSurveyExists] = useState(false)
  const [surveyLoginEventInvalid, setSurveyLoginEventInvalid] = useState(false)

  useEffect(() => {
    if (props) {
      answeredSurvey()
    }
  }, [props])

  async function send() {
    if (props?.user?.idEvent && props?.user?.idPart) {
      const data = {
        eventId: props?.user?.idEvent,
        loginId: props?.user?.idPart,
        question: props?.title,
        answer: answer,
        hit: checkAnswer(answer),
      }
      await postSurvey(data).then(async (response) => {
        if (response.message === 'created') {
          setSurveySuccess(true)
          setSurveyAnswered(true)
          const dataRanking = {
            code: props?.codeRanking,
            positive: true,
            log: 'Acertou enquete',
          }
          if (data.hit) {
            await createEarnedPoints(dataRanking, data?.eventId, data?.loginId).then(response => {
              if (response.message === 'ADDED_TO_QUEUE') {
                // console.log('QUEUED')
              } else {
                // console.log('queue error')
              }
            })
          }
        }
        if (response.message === 'SURVEY_ANSWERED') {
          setSurveyExists(true)
        }
        if (response.message === 'LOGIN_EVENT_NOT_FOUND') {
          setSurveyLoginEventInvalid(true)
        }

      })
        .catch((error) => {
          console.log("erro", error);
        });
    }

  }
  function checkAnswer(answer) {
    return props?.alternatives.filter(e => e?.name === answer)[0]?.isCorrect
  }
  async function answeredSurvey() {
    if (props?.user?.idEvent && props?.user?.idPart) {
      const data = {
        eventId: props?.user?.idEvent,
        loginId: props?.user?.idPart,
        question: props?.title,
        answer: '',
        hit: '',
      }
      await postSurvey(data).then((response) => {
        if (response.message === 'SURVEY_ANSWERED') {
          setSurveyAnswered(true)
        }
        setLoading(false)
      })
        .catch((error) => {
          console.log("erro", error);
        });
    }
  }
  return (
    <>
      {surveySuccess && (
        <SweetAlert
          success
          title="Obrigado!"
          onConfirm={() => {
            setSurveySuccess(false);
          }}
        >
          Enquete respondida com sucesso.
        </SweetAlert>
      )}
      {surveyExists && (
        <SweetAlert
          warning
          title="Parabéns!"
          onConfirm={() => {
            setSurveyExists(false);
          }}
        >
          Você já respondeu essa pergunta.
        </SweetAlert>
      )}
      {surveyLoginEventInvalid && (
        <SweetAlert
          warning
          title="Ops! Algo deu errado"
          onConfirm={() => {
            setSurveyLoginEventInvalid(false);
          }}
        >
          Usuário ou evento inválido.
        </SweetAlert>
      )}

      {!loading && <div className="card-files">
        <div className="items-file">
          <div className="header-item">
            <h1>{props.type}</h1>
            <h4>{surveyAnswered ? 'Parabéns! Resposta computada.' : props?.title}</h4>
            {props.description.map((desc) => (
              <p className="subtitle-files">{desc.data}</p>
            ))}
          </div>
          <div className="list-items">
            {props?.alternatives?.map(e =>
              <div key={e.name} >
                <Input disabled={surveyAnswered} type="radio" name="radio1" value={e.name} onChange={(e) => {
                  setAnswer(e.target.value)
                }} />{' '}
                {e.name}
              </div>
            )}
          </div>

          <a disabled={surveyAnswered} className={!surveyAnswered ? "btn-download" : "btn-disabled"} onClick={() => {
            if (!surveyAnswered) {
              send()
            }
          }} >
            {props.button}
          </a>
        </div>
      </div>}
    </>
  );
}
