import React, { useState, useContext } from "react";
import { QuizContext } from "../../../assets/context/index";
import { Card, CardBody } from "reactstrap";
import "./styled.css";

// services
import { postSurvey } from "../../../services/survey";
import { createEarnedPoints } from "../../../services/ranking";
// componentes

import Question8 from "./question8";
import Question9 from "./question9";
import FinalModal from "./finalModal";

export default function StefaniniEnquete1Questions(props) {
  const { t } = useContext(QuizContext);
  const { user } = props;
  // todas as questões selecionadas
  const [questionsSelected, setQuestionSelected] = useState({});
  // verificar respostas
  const [checkAnswers, setCheckAnswers] = useState(false);
  // modal de finalização
  const [modal, setModal] = useState(false);
  // erro
  const [erro, setErro] = useState(false);
  // carregando btn
  const [loading, setLoading] = useState(false);

  async function verifyAnswers() {
    setLoading(true);
    // verificar se todas foram respondidas
    if (!questionsSelected.question8 || !questionsSelected.question9) {
      setErro(true);
      setLoading(false);
      return;
    } else setErro(false);

    // enviar dados para api
    if (user.idEvent && user.idPart) {
      const data8 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: questionsSelected.question8.title,
        answer: questionsSelected.question8.option,
        hit: questionsSelected.question8.correct,
      };

      await postSurvey(data8).then((res) => {
        if (res.message === "created" && questionsSelected.question8.correct) {
          const dataRanking8 = {
            code: "DATASCIENCEPERGUNTA1",
            positive: true,
            log: "Acertou enquete",
          };

          createEarnedPoints(dataRanking8, user.idEvent, user.idPart);
        }
      });

      const data9 = {
        eventId: user.idEvent,
        loginId: user.idPart,
        question: questionsSelected.question9.title,
        answer: questionsSelected.question9.option,
        hit: questionsSelected.question9.correct,
      };

      await postSurvey(data9).then((res) => {
        if (res.message === "created" && questionsSelected.question9.correct) {
          const dataRanking9 = {
            code: "EVENTOSPERGUNTA1",
            positive: true,
            log: "Acertou enquete",
          };

          createEarnedPoints(dataRanking9, user.idEvent, user.idPart);
        }
      });
    }

    setLoading(false);

    setCheckAnswers(true);

    setModal(true);
  }
  return (
    <section className="section-boe">
      {modal && (
        <FinalModal
          isOpen={modal}
          toggle={() => setModal(!modal)}
          questions={questionsSelected}
        />
      )}

      <Card className="card-boe">
        <CardBody>
          <Question8
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />
          <Question9
            setQuestionSelected={setQuestionSelected}
            checkAnswers={checkAnswers}
          />

          {erro && (
            <div style={{ textAlign: "center" }}>
              <a style={{ color: "red", fontSize: 17 }}>
                {t("Responda todas perguntas")}
              </a>
            </div>
          )}

          <a
            className={`${checkAnswers ? "btn-submit-disabled" : "btn-submit"}`}
            onClick={() => {
              if (!checkAnswers && !loading) {
                verifyAnswers();
              }
            }}
          >
            {t("Salvar respostas")}
          </a>
        </CardBody>
      </Card>
    </section>
  );
}
