import React, { useContext } from "react";
import { Card, CardBody } from "reactstrap";

export default function CardQuestionAnswered() {
  return (
    <section
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#08322a",
      }}
    >
      <Card style={{ width: "90%" }}>
        <CardBody>
          <div style={{ textAlign: "center" }}>
            <h2>Parabéns!</h2>
            <a>Você já escolheu a sua opção de passeio</a>
            <i
              className="mdi mdi-comment-check-outline"
              style={{ color: "green", fontSize: 20 }}
            />
          </div>
        </CardBody>
      </Card>
    </section>
  );
}
