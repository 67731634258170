import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function InformationStart(props) {
  const {
    setStep,
    formRegisterData,
    setFormRegisterData,
    formNotesData,
    setFormNotesData,
    formSoluctionsData,
    setFormSoluctionData,
    cancelGame,
  } = props;
  const [warningAlert, setWarningAlert] = useState("");
  const [phoneMask, setPhoneMask] = useState("");

  function changeNotes(note, id, index) {
    var notes = formNotesData;

    var noteChanged = document.getElementById(id);

    if (Number(note) > 5 || Number(note) < 0) {
      noteChanged.value = notes[index].note;
      return;
    }

    var searchNoteEqual = notes.filter((e) => e.note === note);

    if (searchNoteEqual[0]) {
      searchNoteEqual.map((e, i) => {
        e.note = null;
        document.getElementById(e.id).value = null;
      });
    }

    noteChanged.value = note;
    notes[index].note = note;

    setFormNotesData(notes);
  }

  const solutions = [
    "• Análise de indicadores",
    "• Detecção de patógenos",
    "• Coleta de amostras",
    "• Testes de alergênicos",
    "• Análise UHT",
    "• Análises Nutricionais",
    "• Testes de lactose",
    "• Testes de protease",
    "• Meios de cultura",
    "• Monitoramento de higiene - ATP",
    "• Análise de Micotoxinas",
  ];

  function changeCheckSolutions(solution) {
    var solutions = formSoluctionsData;

    var search = solutions.filter((e) => e === solution);

    if (search[0]) {
      solutions = solutions.filter((e) => e != solution);
    } else {
      solutions.push(solution);
    }

    setFormSoluctionData(solutions);
  }

  function maskPhone(phoneValue) {
    const phone = phoneValue
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    setPhoneMask(
      !phone[2]
        ? phone[1]
        : `(${phone[1]}) ${phone[2]}${phone[3] ? `-${phone[3]}` : ""}`
    );
  }

  function nextStep() {
    const { empresa, cidade, cargo } = formRegisterData;

    if (!empresa || !cidade || !cargo || !phoneMask) {
      setWarningAlert("Para continuar preencha todos os campos");
      return;
    }

    if (phoneMask.replace(/\D/g, "").length < 10) {
      setWarningAlert("Insira todos os dígitos do número do WhatsApp");
      return;
    }

    var searchNoteNull = formNotesData.filter((e) => !e.note || e.note === "0");

    if (searchNoteNull[0]) {
      setWarningAlert(
        "Enumere todas prioridades para seu laboratório com avaliações de 1 à 5"
      );
      return;
    }

    setStep(7);
  }

  useEffect(() => {
    setFormRegisterData((state) => {
      return {
        ...state,
        whatsapp: phoneMask,
      };
    });
  }, [phoneMask]);

  useEffect(() => {
    setFormNotesData([
      {
        title: "• Tempo de liberação de resultados de análise",
        note: null,
        id: "inputNote0",
      },
      {
        title: "• Custo das análises",
        note: null,
        id: "inputNote1",
      },
      {
        title: "• Mão de obra (alta rotatividade, falta de qualificação)",
        note: null,
        id: "inputNote2",
      },
      {
        title: "• Estrutura de laboratório",
        note: null,
        id: "inputNote3",
      },
      {
        title: "• Aprovações e certificações",
        note: null,
        id: "inputNote4",
      },
    ]);
  }, []);

  return (
    <>
      {warningAlert && (
        <SweetAlert
          warning
          title="Atenção"
          onConfirm={() => {
            setWarningAlert("");
          }}
        >
          {warningAlert}
        </SweetAlert>
      )}
      <p className="p-about-neogen">
        <a>
          Estamos muito felizes em ter sua ajuda para definir o controle de
          qualidade de nosso laticínio!
        </a>
      </p>

      <p className="p-about-neogen">
        <a>Para começar, precisamos de algumas informações de cadastro:</a>
      </p>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          nextStep();
        }}
      >
        <Row style={{ marginTop: 10 }}>
          <Col md={6}>
            <label className="form-label">Empresa</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setFormRegisterData((state) => {
                  return {
                    ...state,
                    empresa: e.target.value,
                  };
                });
              }}
            />
          </Col>

          <Col md={6}>
            <label className="form-label">Cargo</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setFormRegisterData((state) => {
                  return {
                    ...state,
                    cargo: e.target.value,
                  };
                });
              }}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col md={6}>
            <label className="form-label">Cidade</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setFormRegisterData((state) => {
                  return {
                    ...state,
                    cidade: e.target.value,
                  };
                });
              }}
            />
          </Col>

          <Col md={6}>
            <label className="form-label">WhatsApp corporativo</label>
            <input
              className="form-control"
              type="text"
              value={phoneMask}
              onChange={(e) => {
                maskPhone(e.target.value);
              }}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col md={6}>
            <h4>
              1. Coloque em ordem de prioridade para seu laboratório os
              seguintes pontos, sendo 1 a prioridade mais alta e 5 a mais baixa:
            </h4>

            <p style={{ display: "flex", flexDirection: "column" }}>
              {formNotesData.map((e, i) => (
                <div key={i} className="div-priority">
                  <input
                    type="number"
                    id={e.id}
                    className="input-priority"
                    onChange={(note) => changeNotes(note.target.value, e.id, i)}
                  />
                  <a>{e.title}</a>
                </div>
              ))}
            </p>
          </Col>

          <Col md={6}>
            <h4>
              2. Quais soluções Neogen você tem interesse em ter mais
              informações?
            </h4>

            <p style={{ display: "flex", flexDirection: "column" }}>
              {solutions.map((e, i) => (
                <div key={i}>
                  <input
                    type="checkbox"
                    className="check-priority"
                    id={`check${i}`}
                    onChange={() => changeCheckSolutions(e)}
                  />
                  <label htmlFor={`check${i}`}>{e}</label>
                </div>
              ))}
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="div-btn-submit">
              {cancelGame && (
                <Button
                  className="btn-danger"
                  style={{ marginRight: 20 }}
                  onClick={() => window.location.reload()}
                >
                  Voltar ao início
                </Button>
              )}
              <Button className="btn-success">Próximo</Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
}
