import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// services
import { verifySurveyByUser } from "../../services/survey";
// componentes
import CardQuestionAnswered from "./cardQuestionAnswered";
import Question from "./question";

export default function Home() {
  // parametros e dados do usuário
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState(null);
  const [step, setStep] = useState(null);

  useEffect(() => {
    if (user) {
      verifySurveyByUser({
        eventId: user.idEvent,
        loginId: user.idPart,
        question: "Escolha seu passeio",
      }).then((res) => {
        if (res.message === "QUESTION_ANSWERED") {
          setStep("b");
        } else if (res.message === "QUESTION_AVAILABLE") {
          setStep("a");
        } else return;
      });
    }
  }, [user]);

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);
  return (
    <section>
      {step === "a" && <Question user={user} />}
      {step === "b" && <CardQuestionAnswered />}
    </section>
  );
}
