import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
//alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { postSurvey } from "../../../services/survey";
import { createEarnedPoints } from "../../../services/ranking";

export default function Step4(props) {
  // alerta
  const [successAlert, setSuccessAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  // campos do form
  const [otherIpunt, setOtherInput] = useState(false);
  const [otherIpuntValue, setOtherInputValue] = useState("");
  const [checkData, setCheckData] = useState("");
  const [avaliation, setAvaliation] = useState("");
  const [avaliationInput, setAvaliationIpunt] = useState("");
  const [loadinBtn, setBtnLoading] = useState(false);
  // estrela
  const [colorStar, setColorStar] = useState("");

  function submitForm(e) {
    setBtnLoading(true);

    if (!e.target[0].value) {
      setAlert('O campo "Qual foi o aplicativo usado?" é obrigatório');
      return;
    }

    if (!checkData && !otherIpunt) {
      setAlert(
        'O campo "O que te motivou utilizar esse aplicativo?" é obrigatório'
      );
      return;
    }

    if (otherIpunt && !otherIpuntValue) {
      setAlert('O campo "Outro (especifique)" é obrigatório');
      return;
    }

    if (!avaliation) {
      setAlert(
        'O campo "Como você avalia sua experiência com esse aplicativo?" é obrigatório'
      );
      return;
    }

    if (!avaliationInput) {
      setAlert('O campo "Qual o motivo da sua nota?" é obrigatório');
      return;
    }

    sendToApi(e);
  }

  async function sendToApi(e) {
    // primeira resposta
    await postSurvey(props.answer[0])
      .then((res) => {
        // console.log("ok", res)
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // segunda resposta
    await postSurvey(props.answer[1])
      .then((res) => {
        // console.log("ok", res)
      })
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // terceira resposta
    const question3 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question: "Qual foi o aplicativo usado?",
      answer: e.target[0].value,
      hit: true,
      // questionOrder: 3,
    };
    await postSurvey(question3)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // quarta resposta
    const question4 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question: "O que te motivou utilizar esse aplicativo?",
      answer: otherIpunt ? checkData + " " + otherIpuntValue : checkData,
      hit: true,
      // questionOrder: 4,
    };
    await postSurvey(question4)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // quinta resposta
    const question5 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question: "Como você avalia sua experiência com esse aplicativo?",
      answer: avaliation,
      hit: true,
      questionOrder: 5,
    };
    await postSurvey(question5)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // sexta resposta
    const question6 = {
      eventId: props.user.idEvent,
      loginId: props.user.idPart,
      question: "Qual o motivo da sua nota?",
      answer: avaliationInput,
      hit: true,
      questionOrder: 6,
    };
    await postSurvey(question6)
      .then((res) => console.log("ok", res))
      .catch(() =>
        setErrorAlert(
          "Erro ao enviar as resposta do formulário, tente novamente em instantes"
        )
      );

    // pontuação
    const dataRanking = {
      code: "DESAFIO4",
      positive: true,
      log: "Acertou enquete",
    };
    await createEarnedPoints(
      dataRanking,
      props.user.idEvent,
      props.user.idPart
    );

    setSuccessAlert(true);
  }

  function addOptionsCheck(option) {
    if (checkData) {
      setCheckData(checkData + `${option}, `);
    } else setCheckData(`${option}, `);
  }

  function removeOptionCheck(option) {
    setCheckData(checkData.replace(`${option}, `, ""));
  }

  useEffect(() => {
    if (avaliation === "Muito ruim") {
      setColorStar("#b10114");
    } else if (avaliation === "Ruim") {
      setColorStar("#ff0136");
    } else if (avaliation === "Neutro") {
      setColorStar("#f9ab00");
    } else if (avaliation === "Bom") {
      setColorStar("#01e161");
    } else if (avaliation === "Muito bom") {
      setColorStar("#03b940");
    } else setColorStar("");
  }, [avaliation]);

  // se ja foi respondido (retornar a essa pergunta), é descartado para uma nova resposta
  useEffect(() => {
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 3));
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 4));
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 5));
    props.setAnswers(props.answer.filter((e) => e.questionOrder != 6));
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitForm(e);
      }}
    >
      {successAlert ? (
        <SweetAlert
          success
          title={"Enviado"}
          onConfirm={() => {
            setBtnLoading(false);
            window.location.reload();
          }}
        >
          Formulário enviado com sucesso
        </SweetAlert>
      ) : null}

      {alert ? (
        <SweetAlert
          warning
          title={"Campo(s) vazio(s)"}
          onConfirm={() => {
            setBtnLoading(false);
            setAlert("");
          }}
        >
          {alert}
        </SweetAlert>
      ) : null}

      {errorAlert ? (
        <SweetAlert
          warning
          title={"Alerta!"}
          onConfirm={() => {
            setBtnLoading(false);
            setErrorAlert("");
          }}
        >
          {errorAlert}
        </SweetAlert>
      ) : null}

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <label className="form-label">3. Qual foi o aplicativo usado?</label>
          <input
            className="form-control"
            type="text"
            placeholder="Sua resposta"
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <label className="form-label" onClick={() => console.log(checkData)}>
            4. O que te motivou utilizar esse aplicativo?
          </label>
          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="option1"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Ofertas e Promoções");
                    } else removeOptionCheck("Ofertas e Promoções");
                  }}
                />
                <label className="form-check-label" htmlFor="option1">
                  Ofertas e Promoções
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="option2"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Preço dos itens");
                    } else removeOptionCheck("Preço dos itens");
                  }}
                />
                <label className="form-check-label" htmlFor="option2">
                  Preço dos itens
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="option3"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Tempo de entrega");
                    } else removeOptionCheck("Tempo de entrega");
                  }}
                />
                <label className="form-check-label" htmlFor="option3">
                  Tempo de entrega
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="option4"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck(
                        "Disponibilidade dos itens que eu estava buscando"
                      );
                    } else
                      removeOptionCheck(
                        "Disponibilidade dos itens que eu estava buscando"
                      );
                  }}
                />
                <label className="form-check-label" htmlFor="option4">
                  Disponibilidade dos itens que eu estava buscando
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="option5"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Taxa de entrega");
                    } else removeOptionCheck("Taxa de entrega");
                  }}
                />
                <label className="form-check-label" htmlFor="option5">
                  Taxa de entrega
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="option6"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Cashback");
                    } else removeOptionCheck("Cashback");
                  }}
                />
                <label className="form-check-label" htmlFor="option6">
                  Cashback
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="option7"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOptionsCheck("Cupom disponível");
                    } else removeOptionCheck("Cupom disponível");
                  }}
                />
                <label className="form-check-label" htmlFor="option7">
                  Cupom disponível
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="option8"
                  // style={{ backgroundColor: "rgb(235, 0, 51)" }}
                  onChange={(e) => setOtherInput(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="option8">
                  Outro (especifique)
                </label>
              </div>
              {otherIpunt && (
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Sua resposta"
                  onChange={(e) => setOtherInputValue(e.target.value)}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <label className="form-label">
            5. Como você avalia sua experiência com esse aplicativo?
          </label>
          <div className="div-avaliation mb-3">
            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Muito ruim")}
              style={{
                backgroundColor: avaliation === "Muito ruim" && "#b10114",
                color: avaliation === "Muito ruim" && "white",
              }}
            >
              <a>Muito ruim</a>
              <i
                className="mdi mdi-star"
                style={{
                  fontSize: 20,
                  color:
                    avaliation === "Ruim" ||
                    avaliation === "Neutro" ||
                    avaliation === "Bom" ||
                    avaliation === "Muito bom"
                      ? colorStar
                      : "",
                }}
              />
            </div>

            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Ruim")}
              style={{
                backgroundColor: avaliation === "Ruim" && "#ff0136",
                color: avaliation === "Ruim" && "white",
              }}
            >
              <a>Ruim</a>
              <i
                className="mdi mdi-star"
                style={{
                  fontSize: 20,
                  color:
                    avaliation === "Neutro" ||
                    avaliation === "Bom" ||
                    avaliation === "Muito bom"
                      ? colorStar
                      : "",
                }}
              />
            </div>

            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Neutro")}
              style={{
                backgroundColor: avaliation === "Neutro" && "#f9ab00",
                color: avaliation === "Neutro" && "white",
              }}
            >
              <a>Neutro</a>
              <i
                className="mdi mdi-star"
                style={{
                  fontSize: 20,
                  color:
                    avaliation === "Bom" || avaliation === "Muito bom"
                      ? colorStar
                      : "",
                }}
              />
            </div>

            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Bom")}
              style={{
                backgroundColor: avaliation === "Bom" && "#01e161",
                color: avaliation === "Bom" && "white",
              }}
            >
              <a>Bom</a>
              <i
                className="mdi mdi-star"
                style={{
                  fontSize: 20,
                  color: avaliation === "Muito bom" ? colorStar : "",
                }}
              />
            </div>

            <div
              className="option-avaliation"
              onClick={() => setAvaliation("Muito bom")}
              style={{
                backgroundColor: avaliation === "Muito bom" && "#03b940",
                color: avaliation === "Muito bom" && "white",
              }}
            >
              <a>Muito bom</a>
              <i className="mdi mdi-star" style={{ fontSize: 20 }} />
            </div>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <label className="form-label">6. Qual o motivo da sua nota?</label>
          <textarea
            className="form-control"
            type="text"
            placeholder="Sua resposta"
            onChange={(e) => setAvaliationIpunt(e.target.value)}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="btn-back" onClick={() => props.setStep("b")}>
              Voltar
            </button>

            <button
              className="btn-submit"
              type="submit"
              disabled={loadinBtn}
              style={{
                backgroundColor: loadinBtn && "gray",
              }}
            >
              {loadinBtn ? "Carregando" : "Enviar"}
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
}
