import axios from "axios";

// const urlBase = `${process.env.REACT_APP_URL_BASE}`;
const urlBase = `https://gateway-admin-plus.sistemasinteegra.com.br`;

function postUploadFile(data) {
  console.log(data);
  var config = {
    method: "post",
    url: `${urlBase}/api-admin-files/uploadapp`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { postUploadFile };
